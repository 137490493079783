import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import React from "react";
import {createStyles, fade, makeStyles, Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: 0,
                width: 'auto',
            },
            transition: 'all .2s'
        },
        searchLight: {
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
        },
        searchDark: {
            backgroundColor: fade(theme.palette.primary.main, 0.05),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.main, 0.10),
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '30ch',
                '&:focus': {
                    width: '40ch',
                },
            },
        },
    }),
);

export interface FilterSearchProps {
    onQuery: (query: string) => any,
    dark?: boolean,
    placeholder?: string,
}

export default function FilterSearch({onQuery, dark, placeholder}: FilterSearchProps) {
    const classes = useStyles();
    const {t} = useTranslation();

    return <div className={`${classes.search} ${dark ? classes.searchDark : classes.searchLight}`}>
        <div className={classes.searchIcon}>
            <SearchIcon/>
        </div>
        <InputBase
            placeholder={placeholder ?? `${t("c.common.titleContains")}...`}
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            onChange={(event) => onQuery(event.target.value)}
            inputProps={{'aria-label': 'search'}}
        />
    </div>
}
