import {Check, CheckCircle} from "@material-ui/icons";
import {Box, Tooltip} from "@material-ui/core";
import React from "react";

export default function DeliverablesSeriesIcon() {
    return <Box color="success.main" display="flex" alignItems="center">
       <Tooltip title="Série">
           <Check fontSize="small" color="inherit"></Check>
       </Tooltip>
    </Box>
}
