import {CheckBox} from "@material-ui/icons";
import {Checkbox} from "@material-ui/core";
import React from "react";
import {useObservable} from "rxjs-hooks";
import {useDataTableContext} from "./context";
import {map, multicast, startWith, tap, withLatestFrom} from "rxjs/operators";
import {combineLatest} from "rxjs";

export default function DataTableHeadSelectBox<T>() {
    const service = useDataTableContext<T>();
    const loading = useObservable(() => service.loading$, true);
    const {
        indeterminate,
        checked,
        disabled,
    } = useObservable(() => combineLatest([
            service.rowsCount$.pipe(
                withLatestFrom(service.rows$)
            ),
            service.selectionCount$.pipe(startWith(0)),
            service.multiSelect$,
        ]).pipe(
        map(([[rowsCount, rows], selectionCount, multiselect]) => {
            if (multiselect && typeof multiselect === 'function') {
                const selectableRowsCount = rows.data.filter(row => multiselect(row)).length;
                return {
                    indeterminate: selectableRowsCount && selectionCount > 0 && selectionCount < selectableRowsCount,
                    checked: selectableRowsCount && selectionCount === selectableRowsCount,
                    disabled: !selectableRowsCount,
                };
            }
            return {
                indeterminate: selectionCount > 0 && selectionCount < rowsCount,
                checked: rowsCount > 0 && selectionCount === rowsCount,
                disabled: false,
            }

        }),
        ), {indeterminate: false, checked: false, disabled: false}
    );

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        service.selectAllToggle(event.target.checked);
    };

    return <Checkbox
        disabled={loading || disabled}
        style={{padding: '9px', margin: '-9px'}}
        indeterminate={!!indeterminate}
        checked={!!checked}
        onChange={handleSelectAll}
    />
}
