import {createMuiTheme, darken, lighten} from "@material-ui/core/styles";
import {blue, green, indigo} from "@material-ui/core/colors";

// @ts-ignore
const isIe = /*@cc_on!@*/false || !!document.documentMode;

const theme = createMuiTheme({
    props: {
        // Uncomment for Performance optimisations for IE
        /*MuiButtonBase: {
            disableRipple: true,
        },*/
        // @ts-ignore
        MuiAutocomplete: {
            noOptionsText: "Aucune option",
            loadingText: "Chargement..."
        }
    },
    palette: {
        primary: {
            main: indigo["900"],
        },
        success: {
            main: green["700"]
        }
    },
    transitions: {
        // So we have `transition: none;` everywhere
        // create: () => 'none',
    },
});

theme.palette.text = {
    ...theme.palette.text,
    ...(!isIe ? {primary: darken(theme.palette.primary.dark, .5)}:{})
};

if (isIe) {
    theme.typography = {
        ...theme.typography,
        h6: {
            ...theme.typography.h6,
            fontWeight: 400,
        },
        // fontWeightBold: 400,
        // fontWeightMedium: 400,
        // fontWeightRegular: 300,
    }
    // theme.palette.text.primary =
}

theme.overrides = {
    MuiOutlinedInput: {
        notchedOutline: {
            borderColor: theme.palette.divider,
        }
    },
    MuiInput: {
        underline: {
            '&:before': {
                borderBottomColor: theme.palette.divider, // Semi-transparent underline
                borderBottomStyle: 'dotted',
                ':hover': {
                    borderBottomColor: theme.palette.divider, // Semi-transparent underline
                }
            },
            '&:hover:not(.Mui-disabled):before': {
                borderBottomColor: theme.palette.divider, // Semi-transparent underline
                borderBottomWidth: '1px'
            }
        },
    },
    MuiCssBaseline: {
        '@global': {
            /*// Uncomment for IE optimisation
            '*, *::before, *::after': {
                transition: 'none !important',
                animation: 'none !important',
            },*/

            '*::-webkit-scrollbar': {
                width: '10px'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.divider,
                // outline: '1px solid slategrey'
            }
        },
    },
    MuiTableRow: {
        root: {
            color: theme.palette.text.primary,
        },
        hover: {
            '&$hover:not($selected):hover': {
                backgroundColor: lighten(theme.palette.secondary.light, .98),
                cursor: "pointer",
            }
        }
    },
    MuiTableCell: {
        head: {
            backgroundColor: lighten(theme.palette.primary.light, .98),
        },
        root: {  //This can be referred from Material UI API documentation.
            padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
            '&:first-child': {
                paddingLeft: theme.spacing(3),
            },
            '&:last-child': {
                paddingRight: theme.spacing(3)
            }
            // backgroundColor: "#eaeaea",
        },
    },
    MuiTableSortLabel: {
        root: {
            '&:hover, &:focus': {
                color: 'inherit'
            }
        }
    }
}

export default theme;
