import {TextField, TextFieldProps} from "@material-ui/core";
import React from "react";

export type CustomTextFieldProps<T> = Omit<TextFieldProps, "variant"> & { name?: keyof T };

export default function CustomTextField<T>(props: CustomTextFieldProps<T>) {
    return <TextField
        margin="dense"
        variant="outlined"
        fullWidth
        {...props}
    />
}
