import {Deliverable} from "../../../../../types/deliverable.types";
import React, {useEffect, useState} from "react";
import moment from "moment";
import CustomDatePicker from "../../../../../../core/components/CustomDatePicker";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";


export default function DeliverablesExpectedDateControl({deliverable, onChange}: { deliverable: Deliverable, onChange: (row: Deliverable) => any }) {
    const [value, setValue] = useState(deliverable.expectedDeliverableReceptionDate);

    const isNotSameDate: boolean =   !!deliverable.expectedDeliverableReceptionDateClient
    && deliverable.expectedDeliverableReceptionDate?.toISOString() !== deliverable.expectedDeliverableReceptionDateClient?.toISOString()
    && !deliverable.actualDeliverableReceptionDate;


    const classes = makeStyles(theme => ({
        datePicker: {
            '& input': {
                color: isNotSameDate ? '#c5003b' : 'initial'
            }
        }
    }))();


    const handleChange = (change: moment.Moment | null) => {
        setValue(change);
        const newDeliverable = {...deliverable};
        newDeliverable.expectedDeliverableReceptionDate = change;
        onChange(newDeliverable);
    };

    useEffect(() => {
        setValue(deliverable.expectedDeliverableReceptionDate);
    }, [deliverable.expectedDeliverableReceptionDate]);

    return <CustomDatePicker
        className={classes.datePicker}
        style={{
            marginTop: '0',
            marginBottom: '0',
            maxWidth: '180px',
        }}
        placeholder="Liv. Prévue"
        format="YYYY-MM-DD"
        disabled={!!deliverable.actualDeliverableReceptionDate}
        onChange={(date) => handleChange(date && date.isValid() ? date : null)}
        value={value}/>
}
