import {EnumsBaseService} from "../../core/services/base/enums.service";
import {Enums} from "../types/enums.types";
import {combineLatest, defer, Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {EnumItem} from "../../core/types/common";
import {DeliverableLinkTypeCode} from "../types/deliverable.types";

export class EnumsService extends EnumsBaseService<Enums> {
    getEnums(): Observable<Enums> {
        return defer(() => combineLatest([
            /*this.getEnum('deliverable_status/', {
                sorted: true
            }),*/
            //this.getEnum('contract_or_agreements/'),
            // this.getEnum('producers_distributors/'),
            //this.getEnum('product_kinds/'),
            //this.getEnum('deliverable_types/'),
            this.getEnum('product_deliverable_status/', {
                
            }),
            this.getEnum('deliverable_status/', {
                addToFillItem: true,
                addToFillItemLabel: "Non renseigné"
            }),
            
            of(this.getDeliverableLinkTypes()),
        ]).pipe(map(([productDeliverablesStatus,
                         //contractOrAgreements,
                         // producersDistributors,
                         //productTypes,
                         //deliverableTypes,
                         deliverableStatus,
                         deliverableLinkTypes]) => ({
            productDeliverablesStatus,
            //contractOrAgreements,
            // producersDistributors,
            //productTypes,
            //deliverableTypes,
            deliverableStatus,
            deliverableLinkTypes,
        }))));
    }

    private getDeliverableLinkTypes() : EnumItem<DeliverableLinkTypeCode>[]{
        return  [
            {
                "id": 1,
                "title": "Série",
                "code": "series",
            },
            {
                "id": 2,
                "title": "Episodes",
                "code": "episodes"
            }
        ];
    }

}
