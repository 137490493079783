import {catchError, tap} from "rxjs/operators";
import {ApiError, ApiService} from "./api.service";
import {BehaviorSubject, EMPTY, Observable, Subject} from "rxjs";

export class AuthService {
    private wrongUserPasswordApiMsg = '[002] Unknown user or wrong password';
    private isLoggedInTrigger$ = new BehaviorSubject(AuthService.isLoggedIn());
    public isLoggedIn$: Observable<boolean> = this.isLoggedInTrigger$.asObservable();

    constructor(private apiService: ApiService) {
        this.apiService.authError$.subscribe(
            () => this.internalLogout()
        );
    }

    login(username: string, password: string): Observable<LoginResult> {
        return this.apiService.post<LoginResult>({
            path: '/auth/login',
            isPublic: true,
            customHandleErrors: [this.wrongUserPasswordApiMsg],
            data: {
                "login": username,
                "password": password,
                "application": "Mogador Senior",
                "application_version": "2.2.0"
            },
        }).pipe(
            tap(({token}) => {
                localStorage.setItem('token', `Token ${token}`);
                this.isLoggedInTrigger$.next(true);
            })
        );
    }

    logout() {
        this.apiService.post({
            path: '/auth/logout',
        }).pipe(tap(() => {
            this.internalLogout();
        })).subscribe();
    }

    private internalLogout() {
        localStorage.removeItem('token');
        this.isLoggedInTrigger$.next(false);
    }

    static getToken(withoutPrefix?: boolean): string {
        let token = localStorage.getItem('token')!;
        if(withoutPrefix)
            token = token.replace('Token ', '');
        return token;
    }

    private static isLoggedIn(): boolean {
        return !!localStorage?.getItem('token');
    }
}

interface LoginResult {
    status: 'SUCCESS' | 'ERROR',
    token: string
}
