import {Chip} from "@material-ui/core";
import React from "react";
import {CheckRounded, WarningRounded} from "@material-ui/icons";
import TextTooltip from "../../../../../../core/components/TextTooltip";
import {EnumItem} from "../../../../../../core/types/common";
import {DeliverableValidationCode} from "../../../../../types/deliverable.types";
import {resolveAny} from "dns";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

export interface ProductValidationBadgeProps {
    badge: EnumItem | null,
    rejectionCause: string,
    actualDeliverableReceptionDate: any,
    expectedDeliverableReceptionDate: any,
    status: EnumItem | null;
}

export default function DeliverablesValidationBadge({badge, rejectionCause, actualDeliverableReceptionDate, expectedDeliverableReceptionDate, status }: ProductValidationBadgeProps) {
    if (!badge)
        return <span>-</span>;

    const code:DeliverableValidationCode =  (!actualDeliverableReceptionDate && expectedDeliverableReceptionDate) ? "pending" :  badge.code as DeliverableValidationCode;

    const color = code === 'rejected' || status?.title === 'Livrable refusé' ? '#c5003b' : (code === 'pending' ? '#e6870c' : '#0b983c' );
    const Icon = code === 'rejected' || status?.title === 'Livrable refusé' ? WarningRounded : (code === 'pending' ? QueryBuilderIcon : CheckRounded);

    return <TextTooltip
        arrow={true}
        title={rejectionCause} hidden={!rejectionCause?.length}>
        <Chip
            style={{
                color: color,
                borderColor: color
            }}
            icon={<Icon
                style={{
                    color: color,
                    marginLeft: '9px'
                }}/>}
            label={badge?.title}
            variant="outlined"/>
    </TextTooltip>
}
