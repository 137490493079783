import {asyncScheduler, BehaviorSubject} from "rxjs";
import {observeOn, share, subscribeOn} from "rxjs/operators";
import {LoadingScreenService} from "./loading-screen.service";

export interface SaveStatus {
    progress: boolean,
    message: string,
    status?: 'success' | 'error',
    error?: any,
    counter?: number,
}

export class SaveStatusService {
    private saveStatusSubject = new BehaviorSubject<SaveStatus>({
        progress: false,
        message: ''
    });
    public saveStatus$ = this.saveStatusSubject.asObservable().pipe(share(), subscribeOn(asyncScheduler), observeOn(asyncScheduler));

    constructor(private loadingScreenService: LoadingScreenService) {
        this.saveStatus$.subscribe(
            (saveStatus) => {
                this.loadingScreenService.setLoading({
                    loading: saveStatus.progress,
                    message: saveStatus.message
                })
            }
        );
    }

    public setSaveStatus(status: SaveStatus) {
        this.saveStatusSubject.next(status);
    }
}
