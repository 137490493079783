import {useObservable} from "rxjs-hooks";
import {
    Box,
    Collapse,
    createMuiTheme,
    createStyles,
    Divider,
    Paper,
    PaperProps,
    Theme,
    useTheme
} from "@material-ui/core";
import React from "react";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {FilterBaseService} from "../../services/base/filter.service";
import {indigo} from "@material-ui/core/colors";

export interface FilterDrawerProps {
    children: React.ReactNode;
    roundedContainer?: boolean;
    filterService: FilterBaseService<any, any, any>;
    light?: boolean;
    paperProps?: PaperProps;
}

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
     /*   primary: {
            main: indigo['300']
        },*/
    }
});
const lightTheme = createMuiTheme({
    palette: {
        type: 'light',
       /* primary: {
            main: indigo['300']
        },*/
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            backgroundColor: theme.palette.primary.dark,
            borderRadius: '0',
        },
        paperLight: {
            backgroundColor: theme.palette.common.white,
        }
    })
);

export default function FilterDrawer({children, roundedContainer, filterService, light, paperProps}: FilterDrawerProps) {
    const classes = useStyles();
    // theme.palette.type = light ? 'light' : 'dark';
    const isVisible = useObservable(() => filterService.visible$);
    return <Collapse in={!!isVisible}>
        <ThemeProvider theme={light ? lightTheme : darkTheme}>
            <Paper className={`${classes.paper} ${light && classes.paperLight}`} {...paperProps}>
                {children}
            </Paper>
        </ThemeProvider>
    </Collapse>

}
