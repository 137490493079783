import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Tous droits réservés © '}
            <Link color="inherit" target="_blank" href="http://proconsultant.net/">
                ProConsultant Informatique
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
