import React from 'react';
import './App.css';
import {FEATURES_SERVICES, FeaturesServicesContext} from "./features/services/context";
import Pages from "./features/components/pages/Pages";
import Core from "./core/components/Core";

function App() {
    return <Core>
        <FeaturesServicesContext.Provider value={FEATURES_SERVICES}>
            <Pages/>
        </FeaturesServicesContext.Provider>
    </Core>;
}

export default App;
