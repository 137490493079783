import React from "react";
import Shell from "../../../core/components/Shell";
import FilterDrawer from "../../../core/components/Filter/FilterDrawer";
import ProductDataTableContent from "../elements/ProductDataTable/ProductDataTableContent";
import ProductFilterFields from "../elements/ProductDataTable/ProductFilterFields";
import {useServicesContext} from "../../services/context";
import MainHeader from "../elements/ProductDataTable/ProductDataTableHeader";

export default function HomePage() {
    const {productsFilterService} = useServicesContext();
    return <Shell
        toolbarContent={<MainHeader/>}
        mainContent={<React.Fragment>
            <ProductDataTableContent/>
        </React.Fragment>}
        drawer={<FilterDrawer filterService={productsFilterService}>
            <ProductFilterFields/>
        </FilterDrawer>}/>;
}
