import React, { useEffect, useState } from "react";
import { useServicesContext } from "../../../../../services/context";
import { useObservable } from "rxjs-hooks";
import { Box } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomAutocomplete from "../../../../../../core/components/CustomAutocomplete";
import CustomTextField from "../../../../../../core/components/CustomTextField";
import CustomDatePicker from "../../../../../../core/components/CustomDatePicker";
import { EnumItem } from "../../../../../../core/types/common";

export default function DeliverablesFilterFields() {
  const {
    deliverablesFilterService,
    productsService,
    enumsService,
    deliverablesService,
    deliverablesFormService,
  } = useServicesContext();
  const selectedProductIsSeries = useObservable(
    () => productsService.selectedProductIsSeries$
  );
  const filter = useObservable(() => deliverablesFilterService.filter$);
  const subFilter = useObservable(() => deliverablesFilterService.subFilter$);
  const enums = useObservable(() => enumsService.enums$);
  const deliverables = useObservable(() => deliverablesService.data$);

  const changeNotSaved = useObservable(
    () => deliverablesFormService.changesCount$
  );
  const canUpdate = !changeNotSaved;

  const [deliverableTypes, setDeliverableTypes] = useState<EnumItem[]>([]);
  const [checkedForNoReceptionDate, setCheckedForNoReceptionDate] =
    useState<boolean>(false);

  useEffect(() => {
    let deliverableTypesEdited: EnumItem[] = deliverableTypes
      ? [...deliverableTypes]
      : [];
    deliverables?.forEach((deliverable, index) => {
      if (deliverableTypesEdited && deliverableTypesEdited.length > 0) {
        if (
          !deliverableTypesEdited.find(
            (type) =>
              type.title.toLocaleLowerCase() ===
              deliverable.deliverableType?.title.toLocaleLowerCase()
          )
        ) {
          if (deliverable.deliverableType) {
            deliverableTypesEdited.push(deliverable.deliverableType);
          }
        }
      } else {
        if (deliverable.deliverableType) {
          deliverableTypesEdited.push(deliverable.deliverableType);
        }
      }
    });
    setDeliverableTypes(deliverableTypesEdited);
  }, [deliverables]);

  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${selectedProductIsSeries ? 4 : 4},1fr)`}
      gridColumnGap="1rem"
    >
      {selectedProductIsSeries && (
        <CustomAutocomplete
          disabled={!canUpdate}
          label={"Lié à"}
          options={enums?.deliverableLinkTypes ?? []}
          onChange={(event, option) =>
            deliverablesFilterService.setSubFilter({ isLinkedTo: option })
          }
        />
      )}
      <CustomAutocomplete
        disabled={!canUpdate}
        label={"Type du Livrable"}
        limitTags={1}
        options={deliverableTypes ?? []}
        multiple={true}
        onChange={(event, options) =>
          deliverablesFilterService.setSubFilter({ deliverableType: options })
        }
      />
      <CustomDatePicker
        disabled={!canUpdate || checkedForNoReceptionDate}
        format="YYYY-MM-DD"
        label={"Proposition Date de Livraison Prévue"}
        onChange={(date) =>
          deliverablesFilterService.setSubFilter({
            expectedDeliverableReceptionDate:
              date && date?.isValid() ? date : undefined,
          })
        }
        value={subFilter?.expectedDeliverableReceptionDate ?? null}
      />
      <CustomDatePicker
        disabled={!canUpdate}
        format="YYYY-MM-DD"
        label={"Date de Livraison Prévue"}
        onChange={(date) =>
          deliverablesFilterService.setSubFilter({
            expectedDeliverableReceptionDateClient:
              date && date?.isValid() ? date.utc() : undefined,
          })
        }
        value={subFilter?.expectedDeliverableReceptionDateClient ?? null}
      />
      <CustomDatePicker
        disabled={!canUpdate}
        format="YYYY-MM-DD"
        label={"Date de Livraison Réelle"}
        onChange={(date) =>
          deliverablesFilterService.setSubFilter({
            actualDeliverableReceptionDate:
              date && date?.isValid() ? date : undefined,
          })
        }
        value={subFilter?.actualDeliverableReceptionDate ?? null}
      />
      <CustomAutocomplete
        disabled={!canUpdate}
        label={"Statut"}
        options={enums?.deliverableStatus ?? []}
        onChange={(event, option) =>
          deliverablesFilterService.setSubFilter({ status: option })
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={checkedForNoReceptionDate}
            onChange={(event, option) => {
              setCheckedForNoReceptionDate(!checkedForNoReceptionDate);
              deliverablesFilterService.setSubFilter({
                noReceptionDate: option || undefined,
              });
            }}
            name="checkedB"
            color="primary"
          />
        }
        label="Sans Proposition Date de Liv. Prévue"
      />

      {selectedProductIsSeries && (
        <CustomTextField
          disabled={!canUpdate}
          label={"N°épisodes"}
          placeholder={"e.g 1-5, 8, 11-13"}
          onChange={(event) =>
            deliverablesFilterService.setSubFilter({
              episodeNumberInterval: event.target.value,
            })
          }
        />
      )}
    </Box>
  );
}
