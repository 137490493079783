import React, {useContext} from "react";
import {CORE_SERVICES} from "../../core/services/context";
import {ProductsService} from "./products.service";
import {ProductsFilterService} from "./products-filter.service";
import {EnumsService} from "./enums.service";
import {DeliverablesService} from "./deliverables.service";
import {DeliverablesFilterService} from "./deliverables-filter.service";
import {DeliverablesFormService} from "./deliverables-form.service";

export const FEATURES_SERVICES: FeaturesServices = initServices();

export const FeaturesServicesContext: React.Context<FeaturesServices> = React.createContext<FeaturesServices>(FEATURES_SERVICES);

export function useServicesContext() {
    return useContext<FeaturesServices>(FeaturesServicesContext);
}

interface FeaturesServices {
    productsService: ProductsService;
    productsFilterService: ProductsFilterService;
    deliverablesService: DeliverablesService;
    deliverablesFilterService: DeliverablesFilterService;
    deliverablesFormService: DeliverablesFormService;
    enumsService: EnumsService;
}

function initServices(): FeaturesServices {
    const {
        apiService,
        dataService,
        authService,
        // loadingScreenService,
        // saveStatusService,
        // toastNotificationsService
    } = CORE_SERVICES;
    const enumsService = new EnumsService(apiService, authService);
    const productsFilterService = new ProductsFilterService();
    const productsService = new ProductsService(apiService, productsFilterService);
    const deliverablesFilterService = new DeliverablesFilterService();
    const deliverablesService = new DeliverablesService(apiService, deliverablesFilterService, productsService);
    const deliverablesFormService = new DeliverablesFormService(
        apiService,
        dataService,
        deliverablesService);

    return {
        productsService,
        productsFilterService,
        deliverablesService,
        deliverablesFilterService,
        deliverablesFormService,
        enumsService,
    }
}
