import {EntityBaseService} from "../../core/services/base/entity.service";
import {ApiDeliverableGet, Deliverable} from "../types/deliverable.types";
import moment from "moment";
import {DeliverableFilter} from "../types/deliverable-filter.types";
import {ApiService} from "../../core/services/api.service";
import {DeliverablesFilterService} from "./deliverables-filter.service";
import {ProductsService} from "./products.service";
import {filter, tap} from "rxjs/operators";

export class DeliverablesService extends EntityBaseService<Deliverable, ApiDeliverableGet, DeliverableFilter> {
    protected entityName: string = 'Deliverables';

    constructor(apiService: ApiService,
                deliverablesFilterService: DeliverablesFilterService,
                private productsService: ProductsService) {
        super(apiService, deliverablesFilterService);
        this.setup();
    }

    public load(productId: number) {
        super.fetch(`/product_deliverable/${productId}/deliverable`);
        this.filterService.clear();
    }

    protected mapToEntity(record: ApiDeliverableGet): Deliverable {
        return {
            id: record.deliverable_id,
            isLinkedToSeries: !!record.series_only,
            episodeNumber: record.episode_number,
            productTitle: record.product_title,
            //deliverableType: this.initEnumItem(record.deliverable_type_id, record.deliverable_type_name),
            deliverableType: record.delivrable_type ? {
                id: record.delivrable_type,
                title: record.delivrable_type
            } : null,
            //deliverableName: record.name,
            actualDeliverableReceptionDate: record.receipt_actual_date?.length ? moment.utc(record.receipt_actual_date) : null,
            expectedDeliverableReceptionDate: record.tp_receipt_expected_date?.length ? moment.utc(record.tp_receipt_expected_date) : null,
            expectedDeliverableReceptionDateClient: record.receipt_expected_date?.length ? moment.utc(record.receipt_expected_date) : null,
            //status: this.initEnumItem(record.status_id, record.status_name, record.status_code),
            status: this.initEnumItem(record.status_id, record.status),
            rejectionCause: record.refusal_reason,
        };
    }

    protected buildQuery(filter: DeliverableFilter): string {
        return "/";
    }

    protected setup() {
        this.dataState$.subscribe();
        this.productsService.selected$.pipe(
            filter((p) => !!p),
        ).subscribe((product) => this.load(product!.id));
    }

}
