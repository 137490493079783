import React from 'react';
// import {makeStyles} from "@material-ui/core/styles";

export type LogoProps = {
    src: string,
    width?: string,
    height?: string,
    style?: React.CSSProperties
}

export default function Logo({src, width, height, style}: LogoProps) {
    return (
        <img src={src} alt="Logo" width={width} height={height} style={style}/>
    );
}
