import {from, Observable, Subject} from "rxjs";
import {share} from "rxjs/operators";
import {LoadingScreenService} from "./loading-screen.service";
import {ApiError} from "./api.service";

export interface ToastNotification {
    type: 'success' | 'error';
    message: string;
    description?: string;
    delay?: number;
}

export class ToastNotificationsService {
    private toastNotificationSubject = new Subject<ToastNotification>();
    public toastNotification$: Observable<ToastNotification> = this.toastNotificationSubject.asObservable().pipe(share());

    constructor(private loadingScreenService: LoadingScreenService) {
    }

    public notify(notification: ToastNotification) {
        this.loadingScreenService.setLoading({
            loading: false,
            message: notification.message
        });
        this.toastNotificationSubject.next(notification);
    }

    public apiErrorNotify({response, payload}: ApiError, customNotification?: Partial<ToastNotification>) {
        const {url, statusText, status} = response;
        const notification: ToastNotification = {
            type: "error",
            message: "API Error",
            description: `URL: ${url}\nStatus: ${status} - ${statusText}`,
            delay: 60000,
            ...customNotification
        };
        if (payload.message) {
            notification.description += `\nMessage: ${payload.message}`
        }
        this.notify(notification);
    }

    public parseReadableError(error: any) {
        return (error?.statusText && `API: ${error?.statusText}`) || error?.message || JSON.stringify(error);
    }
}
