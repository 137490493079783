import Badge from "@material-ui/core/Badge";
import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import React from "react";

export interface FilterToggleProps {
    onToggle: (isVisible: boolean) => any;
    isVisible: boolean;
    filterCount: string | number;
}

export default function FilterToggle({filterCount, isVisible, onToggle}: FilterToggleProps) {
    return <IconButton type={"button"} onClick={() => onToggle(!isVisible)}
                       color="inherit">
        <Badge badgeContent={filterCount} color="secondary" invisible={filterCount == 0}>
            <FilterListIcon/>
        </Badge>
    </IconButton>
}
