import {Box, Divider, Toolbar, useTheme} from "@material-ui/core";
import FilterBar from "../../../../../../core/components/Filter/FilterBar";
import {FilterBarWithoutTitleFilter} from "../../../../../../core/components/Filter/FilterBar";
import React from "react";
import {useServicesContext} from "../../../../../services/context";
import {useObservable} from "rxjs-hooks";
import CustomToolbar from "../../../../../../core/components/CustomToolbar";
import FilterDrawer from "../../../../../../core/components/Filter/FilterDrawer";
import DeliverablesFilterFields from "./DeliverablesFilterFields";
import DeliverablesBulkEditButton from "./DeliverablesBulkEditButton";
import DeliverablesButtons from "./DeliverablesButtons";

export default function DeliverablesDataTableHeader() {
    const {productsService, deliverablesFilterService} = useServicesContext();
    const theme = useTheme();
    const selectedProductIsSeries = useObservable(() => productsService.selectedProductIsSeries$);
    return <React.Fragment>
        <CustomToolbar title="Livrables Liés">
            <Box
                marginLeft={3}
                width="100%"
                display="flex"
                // color="primary.dark"
                alignItems="center">
                {selectedProductIsSeries ?
                    <FilterBar
                    dark={true} filterService={deliverablesFilterService}/>
                    :
                    <FilterBarWithoutTitleFilter
                    dark={true} filterService={deliverablesFilterService}/>
                }
                <Box ml="auto">
                    <DeliverablesButtons/>
                    {/*<DeliverablesBulkEditButton/>*/}
                </Box>
            </Box>
        </CustomToolbar>
        <FilterDrawer
            light={true}
            paperProps={{
            variant: 'outlined',
            style: {
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom: 'none',
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                marginBottom: "2px"
                // backgroundColor: theme.palette.primary.main
            }
        }} filterService={deliverablesFilterService}>
            <DeliverablesFilterFields/>
        </FilterDrawer>
    </React.Fragment>
}
