import {fromFetch} from "rxjs/fetch";
import {asyncScheduler, EMPTY, from, Observable, of, Subject, throwError} from "rxjs";
import {delay, finalize, map, observeOn, shareReplay, subscribeOn, switchMap, take, tap} from "rxjs/operators";
import MOCK from '../../mock.json';
import {ToastNotificationsService} from "./toast-notifications.service";
import {AuthService} from "./auth.service";

interface Options {
    path: string;
    isPublic?: boolean;
    autoHandleErrors?: boolean;
    customHandleErrors?: string[];
}

export interface PostOptions extends Options {
    data?: Object | string;
    tryParseId?: boolean;
}

export interface GetOptions extends Options {
}

export interface SavePayload {
    status: 'SUCCESS' | 'FAIL' | 'TODO',
    message: string,
    __isNew?: boolean,
    __id?: number | string,
}

export class ApiError extends Error {
    constructor(public response: Response, public payload: any) {
        super(`Api Error: ${response.status} - ${response.statusText}`);
    }
}

export class ApiService {
    // @ts-ignore
    private mockDelay: number = window.mockThrottleInMs ?? 900;
    private mock: any = MOCK;
    // @ts-ignore
    public api: string = window.api;
    // @ts-ignore
    private imagesRootPath = window.imagesRootPath ?? '/';
    // @ts-ignore
    private isMock: boolean = window.enableMock;
    private authErrorSubject: Subject<true> = new Subject();
    public authError$: Observable<true> = this.authErrorSubject.asObservable().pipe(shareReplay(1));
    // @ts-ignore
    private isMockFor: string[] = window.enableMockFor ?? [];
    private isDefaultPublic: boolean = true;

    constructor(private toastNotificationsService: ToastNotificationsService) {
    }

    get<T>(options: GetOptions): Observable<T> {
        if (this.isMock || this.isMockFor.includes(options.path)) {
            const mockGET = this.mock['GET'];
            if (!mockGET) {
                throw new Error(`${options.path}: no GET object found on mock data`);
            }
            let path;
            if (!mockGET.hasOwnProperty(options.path)) {
                Object.keys(mockGET).filter((f) => f.includes('****')).forEach((f) => {
                    const key = f.replace('****', '');
                    if (options.path.includes(key)) {
                        path = f;
                    }
                });
                if (!path) {
                    throw new Error(`${options.path} not found on mock data`);
                }
            } else {
                path = options.path
            }
            return of(mockGET[path]).pipe(
                delay(this.mockDelay), tap((value) => console.log(`mock for ${options.path}`, value)),
                observeOn(asyncScheduler),
                subscribeOn(asyncScheduler),);
        } else {
            return fromFetch(this.getUrl(options.path), {
                mode: 'cors',
                credentials: undefined,
                method: 'GET',
                headers: this.getHeaders(options.isPublic),
            }).pipe(
                switchMap((response) => this.handleResponse(response, options.autoHandleErrors ?? true, options.customHandleErrors)),
                observeOn(asyncScheduler),
                subscribeOn(asyncScheduler),
            );
        }
    }

    post<T = SavePayload>(options: PostOptions): Observable<T> {
        if (this.isMock) {
            return of({} as T).pipe(
                delay(this.mockDelay),
                tap((value) => console.log('Mock POST:', options.path, options.data)),
            );
        } else {
            return fromFetch(this.getUrl(options.path), {
                method: 'POST',
                headers: this.getHeaders(options.isPublic),
                body: JSON.stringify(options.data)
            }).pipe(
                switchMap((response) => this.handleResponse(response, options.autoHandleErrors ?? false, options.customHandleErrors)),
                map((data) => (options.tryParseId ? {
                    ...data,
                    __id: this.parseIdFromMessage(data),
                    __isNew: true,
                } as SavePayload : data))
            );
        }
    }

    put<T>(options: PostOptions): Observable<T> {
        if (this.isMock) {
            return of({} as T).pipe(
                delay(this.mockDelay),
                tap((value) => console.log('Mock Put:', options.path, options.data)),
            );
        } else {
            return fromFetch(this.getUrl(options.path), {
                method: 'PUT',
                headers: this.getHeaders(options.isPublic),
                body: JSON.stringify(options.data)
            }).pipe(switchMap((response) => this.handleResponse(response, options.autoHandleErrors ?? false, options.customHandleErrors)));
        }
    }

    delete<T>(options: Options): Observable<T> {
        if (this.isMock) {
            return of({} as T).pipe(
                delay(this.mockDelay),
                tap((value) => console.log('Mock Delete:', options.path)),
            );
        } else {
            return fromFetch(this.getUrl(options.path), {
                method: 'DELETE',
                headers: this.getHeaders(options.isPublic),
            }).pipe(switchMap((response) => this.handleResponse(response, options.autoHandleErrors ?? false, options.customHandleErrors)));
        }
    }

    postImage(relativePath: string, base64: string): Observable<void> {
        return this.post({
            path: `/images${this.imagesRootPath}${relativePath}`,
            data: {
                base64: base64
            }
        })
    }

    getImageUrl(relativePath: string): string {
        return `${this.api}/images${this.imagesRootPath}${relativePath}?jwt=${AuthService.getToken(true)}`
    }

    private handleResponse<T>(response: Response, autoHandleErrors: boolean, customHandleErrors?: string[]): Observable<any> {
        if (response.ok) {
            return from(response.json());
        } else {
            return from(response.json()).pipe(
                switchMap((responsePayload) => {
                    if ([403, 401].includes(response.status)) {
                        this.authErrorSubject.next(true);
                    } else {
                        if (!autoHandleErrors || (customHandleErrors && (customHandleErrors.length || customHandleErrors.includes(responsePayload.message)))) {
                            throw new ApiError(response, responsePayload);
                        } else {
                            this.toastNotificationsService.apiErrorNotify(new ApiError(response, responsePayload));
                        }
                    }
                    return EMPTY;
                })
            );
        }
    }

    private getHeaders(isPublic?: boolean) {
        const headers = {
            'Content-Type': 'application/json',
        };
        return /* (isPublic || this.isDefaultPublic) ? headers :*/ {
            ...headers,
            'Authorization': AuthService.getToken()
        };
    }

    private parseIdFromMessage(payload: SavePayload): number | null {
        const matches = payload.message.match(/\d+/g);
        return matches ? +matches[0] : null;
    }

    private getUrl(path: string = '') {
        path = path[0] == '/' ? path : `/${path}`;
        // path = path.slice(-1) == '/' ? path : `${path}/`;
        return `${this.api}${path}`;
    }
}
