import {Change, ChangeType, EnumItem} from "../types/common";
import _ from "lodash";

export function RepeatItem<T>(count: number, item: T) {
    const data: T[] = [];
    for (let i = 0; i < count; i++) {
        data.push(item);
    }
    return data;
}

export function SameEnumItems(a: EnumItem[], b: EnumItem[]) {
    return ArrayEquals(a, b, (a, b) => a.id == b.id);
}

export function ArrayEquals<TA, TB>(a: TA[], b: TB[], isEqual: (va: TA, vb: TB) => boolean) {
    if (a.length !== b.length) {
        return false;
    } else {
        return !a.some(va => !b.find(vb => isEqual(va, vb)));
    }
}

export function SortEnum(list: EnumItem[]) {
    list.sort((a, b) => {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    })
    return list;
}

export function getChanges<T>(before: T[],
                              after: T[],
                              getId: (item: T) => keyof T,
                              isNew: (item: T) => boolean): {
    added: T[],
    updated: T[],
    deleted: T[],
} {
    const deleted = before.filter(itemBefore => {
        const id = getId(itemBefore);
        return !after.some((itemAfter) => getId(itemAfter) == id);
    });
    const updated = before.filter(itemBefore => {
        const id = getId(itemBefore);
        return after.some((itemAfter) => getId(itemAfter) == id && _.isEqual(itemBefore, itemAfter));
    });
    const added = after.filter(itemAfter => isNew(itemAfter));

    return {
        added,
        updated,
        deleted
    }
}

export function getChangesCombined<T>(before: T[],
                                      after: T[],
                                      getId: (item: T) => keyof T,
                                      isNew: (item: T) => boolean): Change<T>[] {
    const {added, updated, deleted} = getChanges(before, after, getId, isNew);

    const setChange = (type: ChangeType) => (item: T) => ({
        ...item,
        __changeType: type
    } as Change<T>);

    return [
        ...added.map(setChange('add')),
        ...updated.map(setChange('edit')),
        ...deleted.map(setChange('delete'))
    ];
}

export function GenerateEnumFrom<T>(items: T[], getValue: (item: T) => string|number) {
    const values = Array.from((new Set(items.map((s) => getValue(s)))).entries());
    values.sort();
    return values.map(([v]) => ({
        id: v,
        title: `${v}`
    }));
}
