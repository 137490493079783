import {LabelDisplayedRowsArgs} from "@material-ui/core/TablePagination/TablePagination";
import TablePagination from "@material-ui/core/TablePagination";
import React, {useEffect} from "react";
import {useDataTableContext} from "./context";
import {useObservable} from "rxjs-hooks";
import {PaginationOptions} from "./types";

export default function DataTablePagination<T>() {
    const service = useDataTableContext<T>();
    const pagination = useObservable<PaginationOptions>(() => service.pagination$);
    const rowsCount = useObservable(() => service.rowsCount$, 0);

    if (!pagination)
        return null;

    return <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]}
        component="div"
        count={rowsCount ?? pagination.rowsPerPage}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        labelRowsPerPage={pagination.labelRowsPerPage}
        labelDisplayedRows={(info: LabelDisplayedRowsArgs) => `${info.from}-${info.to} ${pagination?.labelDisplayedRowsMiddle} ${info.count}`}
        onChangePage={(event: any, page: number) => service.changePage(page)}
        onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => service.changeRowsPerPage(parseInt(event.target.value, 10))}
    />;
}
