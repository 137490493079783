import {Box, Button} from "@material-ui/core";
import React, {ReactNode} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    buttonsGroup: {
        '& button+button': {
            marginLeft: theme.spacing(2)
        }
    }
}));

export default function FormPageFooter({children, className}: {children: ReactNode, className?: string}) {
    const classes = useStyles();

    return <Box mt={2} pt={1} pb={2} display="flex" className={className}>
        <Box ml="auto" className={classes.buttonsGroup}>
            {children}
        </Box>
    </Box>
}
