import {Box, CircularProgress, IconButton, Tooltip} from "@material-ui/core";
import Logo from "../../../../core/components/Logo";
import FilterBar from "../../../../core/components/Filter/FilterBar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React, {useState} from "react";
import {useCoreServicesContext} from "../../../../core/services/context";
import {useServicesContext} from "../../../services/context";

export default function ProductDataTableHeader() {
    const {authService} = useCoreServicesContext();
    const {productsFilterService} = useServicesContext();
    const [logingOut, setLogingOut] = useState(false);

    const handleLogout = () => {
        setLogingOut(true);
        authService.logout();
    }

    return <Box
        width="100%"
        display="flex"
        alignItems="center">
        <Box mr={2} display="flex" alignItems="center">
            <Logo src="./logo-icon.svg" width="160px"/>
        </Box>
        <FilterBar filterService={productsFilterService}/>
        <Box ml="auto">
            {
                logingOut ? <CircularProgress size={20} color="inherit"/> :
                    <Tooltip title="Se déconnecter">
                        <IconButton
                            style={{marginRight: '-12px'}}
                            onClick={() => handleLogout()} color="inherit">
                            <ExitToAppIcon/>
                        </IconButton>
                    </Tooltip>
            }
        </Box>
    </Box>
}
