import {Box} from "@material-ui/core";
import React from "react";
import FilterSearch from "./FilterSearch";
import FilterToggle from "./FilterToggle";
import {useObservable} from "rxjs-hooks";
import {FilterBaseService} from "../../services/base/filter.service";

export default function FilterBar({filterService, dark, searchPlaceholder}: {
    dark?: boolean,
    filterService: FilterBaseService<any, any, any>,
    searchPlaceholder?: string,
}) {
    const isVisible = useObservable(() => filterService.visible$);
    const filterCount = useObservable(() => filterService.filterCount$, 0);

    return <Box display="flex" alignItems="center">
        <FilterSearch
            placeholder={searchPlaceholder}
            dark={dark}
            onQuery={(query) => filterService.setSearchQuery(query)}/>
        <Box ml={.75}>
            <FilterToggle
                isVisible={!!isVisible}
                filterCount={filterCount}
                onToggle={() => filterService.toggle(!isVisible)}/>
        </Box>
    </Box>
}

export function FilterBarWithoutTitleFilter({filterService, dark, searchPlaceholder}: {
    dark?: boolean,
    filterService: FilterBaseService<any, any, any>,
    searchPlaceholder?: string,
}) {
    const isVisible = useObservable(() => filterService.visible$);
    const filterCount = useObservable(() => filterService.filterCount$, 0);

    return <Box display="flex" alignItems="center">
        <Box ml={.75}>
            <FilterToggle
                isVisible={!!isVisible}
                filterCount={filterCount}
                onToggle={() => filterService.toggle(!isVisible)}/>
        </Box>
    </Box>
}