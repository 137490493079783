import {DataTableColumn, DataTableColumnRowInfo} from "./types";
import {firstBy} from "thenby";
import moment, {isMoment} from "moment";

export function sort<T>(rows: T[], columns: DataTableColumn<T>[]) {
    const sortColumns = columns.filter((c) => !!c.orderRank);
    if (!sortColumns.length) {
        return rows;
    }
    sortColumns.sort(firstBy("orderRank", "asc"));
    const sortBys = sortColumns.reduce((sortBys, column, index) => {
        if (!sortBys) {
            return firstBy((a: T, b: T) => compare<T>(a, b, column), column.order);
        } else {
            return (sortBys as any).thenBy((a: T, b: T) => compare<T>(a, b, column), column.order);
        }
    }, undefined) as any;
    const newRows = [...rows];
    newRows.sort(sortBys);
    return newRows;
}

export function compare<T>(a: T, b: T, {id, orderBy, orderAs}: DataTableColumn<T>) {
    let aValue: any = orderBy ? orderBy(a) : a[id];
    let bValue: any = orderBy ? orderBy(b) : b[id];

    if (orderAs === 'moment') {
        const aDate = aValue as unknown as (moment.Moment | null);
        const bDate = bValue as unknown as (moment.Moment | null);
        if (!aDate && !bDate) {
            return 0;
        }
        if (!bDate || bDate.isBefore(aDate)) {
            return -1;
        }
        if (!aDate || bDate.isAfter(aDate)) {
            return 1;
        }
        return 0;
    }

    if (orderAs === 'number') {
        aValue = +a[id];
        bValue = +b[id];
        aValue = !isNaN(aValue) ? aValue : 0;
        bValue = !isNaN(bValue) ? bValue : 0;
    }

    if (!orderAs) {
        aValue = getDefaultOrderAsValue(aValue);
        bValue = getDefaultOrderAsValue(bValue);
    }

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}

export function generateDummyRow<T>(columns: DataTableColumnRowInfo<T>[]): T {
    const row: any = {};
    columns.forEach((c) => {
        row[c.id] = null;
    });
    return row;
}

export function getDefaultOrderAsValue(a: any): string {
    if (typeof a === 'string') {
        return a;
    } else if (!a) {
        return '';
    } else if (a.title) {
        return a.title;
    } else {
        return a;
    }
}
