import React, {useContext} from "react";
import {DataTableService} from "./service";
import {useObservable} from "rxjs-hooks";
import {Observable} from "rxjs";

export const DataTableContext: React.Context<DataTableService> =
    React.createContext<DataTableService>(new DataTableService<any>());

export function useDataTableContext<T>() {
    return useContext<DataTableService<T>>(DataTableContext);
}

export function useDataTable<T, State = any>(factory: (service: DataTableService<T>) => Observable<State>) {
    const service = useContext<DataTableService<T>>(DataTableContext);
    return useObservable(() => factory(service));
}
