import React, { useEffect, useState } from "react";
import { useServicesContext } from "../../../services/context";
import { useObservable } from "rxjs-hooks";
import { Box } from "@material-ui/core";
import CustomAutocomplete from "../../../../core/components/CustomAutocomplete";
import CustomTextField from "../../../../core/components/CustomTextField";
import { EnumItem } from "../../../../core/types/common";

export default function ProductFilterFields() {
  const { productsFilterService, enumsService, productsService } =
    useServicesContext();
  const filter = useObservable(() => productsFilterService.filter$);
  const subFilter = useObservable(() => productsFilterService.subFilter$);
  const enums = useObservable(() => enumsService.enums$);
  const products = useObservable(() => productsService.data$);
  const [productTypes, setProductTypes] = useState<EnumItem[]>([]);
  const [contractOrAgreements, setContractOrAgreements] = useState<EnumItem[]>(
    []
  );

  useEffect(() => {
    if (!filter?.productDeliverablesStatus) {
      productsFilterService.setFilter({
        productDeliverablesStatus: {
          id: "500006370",
          title: "En cours",
        },
      });
    }
  }, []);

  useEffect(() => {
    let productTypesEdited: EnumItem[] = productTypes ? [...productTypes] : [];
    let contractOrAgreementsEdited: EnumItem[] = contractOrAgreements
      ? [...contractOrAgreements]
      : [];
    products?.forEach((product) => {
      if (productTypesEdited && productTypesEdited.length > 0) {
        if (
          !productTypesEdited.find(
            (type) =>
              type.title.toLocaleLowerCase() ===
              product.productType?.title.toLocaleLowerCase()
          )
        ) {
          if (product.productType) {
            productTypesEdited.push(product.productType);
          }
        }
      } else {
        if (product.productType) {
          productTypesEdited.push(product.productType);
        }
      }
      if (contractOrAgreementsEdited && contractOrAgreementsEdited.length > 0) {
        if (
          !contractOrAgreementsEdited.find(
            (type) =>
              type.title.toLocaleLowerCase() ===
              product.type?.title.toLocaleLowerCase()
          )
        ) {
          if (product.type) {
            contractOrAgreementsEdited.push(product.type);
          }
        }
      } else {
        if (product.type) {
          contractOrAgreementsEdited.push(product.type);
        }
      }
    });
    setProductTypes(productTypesEdited);
    setContractOrAgreements(contractOrAgreementsEdited);
  }, [products]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(5,1fr)"
      gridColumnGap="1rem"
    >
      <CustomAutocomplete
        label={"Livrables"}
        options={enums?.productDeliverablesStatus ?? []}
        onChange={(event, option) =>
          productsFilterService.setFilter({ productDeliverablesStatus: option })
        }
        // @ts-ignore
        value={filter?.productDeliverablesStatus ?? null}
      />
      <CustomAutocomplete
        label={"Contrat/Entente"}
        options={contractOrAgreements ?? []}
        onChange={(event, option) =>
          productsFilterService.setSubFilter({ type: option })
        }
        value={subFilter?.type ?? null}
      />
      {/*<CustomTextField
            label={"N°Contrat"}
            onChange={(e) =>
                productsFilterService.setSubFilter({contractNumber: e.target.value})
            }
            value={subFilter?.contractNumber ?? ''}
        />*/}
      <CustomAutocomplete
        label={"Producteur/Distributeur"}
        options={[]}
        onChange={(event, option) =>
          productsFilterService.setSubFilter({ producerDistributor: option })
        }
        value={subFilter?.producerDistributor ?? null}
      />
      <CustomAutocomplete
        label={"Type de Produit"}
        options={productTypes ?? []}
        onChange={(event, option) =>
          productsFilterService.setSubFilter({ productType: option })
        }
        value={subFilter?.productType ?? null}
      />
    </Box>
  );
}
