import {Autocomplete} from "@material-ui/lab";
import {EnumItem} from "../types/common";
import React from "react";
import {Checkbox, TextField, TextFieldProps} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {AutocompleteProps} from "@material-ui/lab/Autocomplete/Autocomplete";
import CustomController from "./CustomController";
import {Control} from "react-hook-form";
import {AutocompleteInputChangeReason} from "@material-ui/lab/useAutocomplete/useAutocomplete";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";
import {useTranslation} from "react-i18next";

export type AutocompleteBaseProps<T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined> =
    {
        key?: string,
        options: EnumItem[],
        label?: string,
        grouped?: boolean,
        error?: boolean,
        placeholder?: string,
        loading?: boolean,
        disabled?: boolean,
        required?: boolean,
        onInputChange?: (
            event: React.ChangeEvent<{}>,
            value: string,
            reason: AutocompleteInputChangeReason
        ) => void,
        inputProps?: Partial<StandardInputProps>,
        variant?: TextFieldProps['variant'],
    }
    & Omit<AutocompleteProps<EnumItem, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'getOptionLabel' | 'noOptionsText'>;

export default function CustomAutocomplete<T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined>(props: AutocompleteBaseProps<EnumItem, Multiple, DisableClearable, FreeSolo>
) {
    const {label, error, placeholder, grouped, required, inputProps, multiple, variant, ...otherProps} = props;
    const {t} = useTranslation();
    if (grouped) {
        otherProps.groupBy = (option) => option.parent!.title;
        otherProps.getOptionSelected = (a, b) => a?.id == b?.id && a?.parent?.id == b?.parent?.id;
    }

    if (multiple) {
        otherProps.renderOption = (option, state) => {
            const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
            const checkedIcon = <CheckBoxIcon fontSize="small"/>;

            return <React.Fragment>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8, padding: 2}}
                    checked={state.selected}
                />
                <span>{option.title}</span>
            </React.Fragment>
        }
    }

    return <Autocomplete
        multiple={multiple}
        size="small"
        noOptionsText={t("c.common.noOption")}
        loadingText={`${t("c.common.loading")}...`}
        getOptionLabel={(option) => option?.title}
        getOptionSelected={(a, b) => a?.id == b?.id}
        fullWidth
        renderInput={(params) => <TextField {...params}
                                            label={label}
                                            error={error}
                                            InputProps={{
                                                ...params.InputProps,
                                                ...inputProps
                                            }}
                                            required={required}
                                            placeholder={placeholder}
                                            margin="dense"
                                            variant={(variant ?? ('outlined')) as 'outlined'}
        />}
        {...otherProps}
    />;
}

export type CustomAutocompleteControllerProps<T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined> =
    AutocompleteBaseProps<T, Multiple, DisableClearable, FreeSolo>
    & { name: (keyof T) | string, control: Control };

export function CustomAutocompleteController<T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined>({name, control, onChange, ...autocompleteProps}: CustomAutocompleteControllerProps<T, Multiple, DisableClearable, FreeSolo>
) {
    return <CustomController
        key={(autocompleteProps.defaultValue as EnumItem)?.id}
        name={name as string}
        control={control}
        defaultValue={autocompleteProps.defaultValue}
        render={({onChange: controlOnChange, value}) => {
            return <CustomAutocomplete
                value={value}
                onChange={(event, value, reason) => {
                    controlOnChange(value);
                    onChange && onChange(event, value, reason);
                }}
                {...autocompleteProps}/>;
        }}/>
}
