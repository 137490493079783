import React from "react";
import Shell from "../../../core/components/Shell";
import ProductDetailsHeader from "../elements/ProductDetails/ProductDetailsHeader";
import ProductDetailsContent from "../elements/ProductDetails/ProductDetailsContent";

export default function ProductDetailsPage() {
    return <Shell toolbarContent={<ProductDetailsHeader/>}
                  mainContent={<ProductDetailsContent/>}
    />
}
