import LoginPage from "./LoginPage";
import React from "react";
import {useServicesContext} from "../../services/context";
import {useObservable} from "rxjs-hooks";
import HomePage from "./HomePage";
import ProductDetailsPage from "./ProductDetailsPage";
import {useCoreServicesContext} from "../../../core/services/context";
import {map} from "rxjs/operators";

export default function Pages() {
    const {authService} = useCoreServicesContext();
    const {productsService} = useServicesContext();
    const signedIn = useObservable<boolean>(() => authService.isLoggedIn$);
    const productFormOpen = useObservable<boolean>(() => productsService.selected$.pipe(
        map(p => !!p)
    ));
    if (!signedIn) {
        return <LoginPage/>
    }
    if (productFormOpen) {
        return <ProductDetailsPage/>;
    } else {
        return <HomePage/>;
    }
}
