import React from "react";
import {useObservable} from "rxjs-hooks";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Dialog, Fade} from "@material-ui/core";
import {TransitionProps} from "@material-ui/core/transitions";
import {Close} from "@material-ui/icons";
import Shell from "../../../../../../core/components/Shell";
import {useServicesContext} from "../../../../../services/context";
import {DeliverableForm} from "../../../../../types/deliverable.types";
import FormPageHeader from "../../../../../../core/components/FormPageHeader";
import FormPageFooter from "../../../../../../core/components/FormPageFooter";
import DeliverablesFormContent from "./DeliverablesFormContent";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});


export default function DeliverablesFormDialog() {
    const {deliverablesFormService} = useServicesContext();
    const formMethods = useForm<DeliverableForm>();
    const title = 'Saisie en masse';
    const isOpen = useObservable(() => deliverablesFormService.isOpen$, false);

    const onSubmit = (data: DeliverableForm) => {
        console.log('data submit', data);
        deliverablesFormService.save(data);
    }

    const handleClose = () => {
        deliverablesFormService.close();
    };


    return <Dialog maxWidth="sm"
                   scroll="body"
                   open={isOpen}
                   TransitionComponent={Transition}
                   onClose={handleClose}>
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Shell toolbarContent={<FormPageHeader
                    exitIcon={<Close/>}
                    title={title}
                    onExitClick={handleClose}/>}
                       mainContent={
                           <React.Fragment>
                               <DeliverablesFormContent/>
                               <FormPageFooter>
                                   <Button color="primary" onClick={handleClose}>
                                       Annuler
                                   </Button>
                                   <Button type="submit" variant="contained" color="primary">
                                       Enregistrer
                                   </Button>
                               </FormPageFooter>
                           </React.Fragment>
                       }
                       mainContentProps={{
                           bgcolor: "#fff",
                           paddingBottom: 2,
                           paddingTop: 4,
                       }}
                />
            </form>
        </FormProvider>
    </Dialog>
}
