import moment from "moment";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React, {useEffect, useState} from "react";
import {KeyboardDatePickerProps} from "@material-ui/pickers/DatePicker/DatePicker";
import {ThemeProvider} from "@material-ui/core/styles";
import {Collapse, Theme} from "@material-ui/core";
import {Control, Controller} from "react-hook-form";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {useTranslation} from "react-i18next";

import "moment/locale/it";
import "moment/locale/fr";

const customTheme = (outerTheme: Theme): Theme => ({
    ...outerTheme,
    overrides: {
        ...outerTheme.overrides,
        MuiButton: {
            ...outerTheme.overrides?.MuiButton,
            textPrimary: {
                color: outerTheme.palette.primary.contrastText,
                backgroundColor: outerTheme.palette.primary.main,
                '&:hover, &:focus': {
                    backgroundColor: outerTheme.palette.primary.main,
                }
            }
        },
        MuiOutlinedInput: {
            ...outerTheme.overrides?.MuiOutlinedInput,
            adornedEnd: {
                paddingRight: '0'
            }
        }
    },
});

export type CustomDatePickerProps = KeyboardDatePickerProps;

export default function CustomDatePicker(props: CustomDatePickerProps) {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    return <MuiPickersUtilsProvider libInstance={moment}
                                    locale={i18n.language}
                                    utils={MomentUtils}>
        <ThemeProvider theme={customTheme}>
            <KeyboardDatePicker
                inputVariant="outlined"
                margin="dense"
                autoOk={false}
                variant="dialog"
                format="DD-MM-yyyy"
                okLabel={t("c.common.ok")}
                cancelLabel={t("c.common.cancel")}
                showTodayButton={true}
                todayLabel={t("c.common.today")}
                invalidDateMessage={t("c.common.invalidDate")}
                fullWidth
                {...props}
            />
        </ThemeProvider>
    </MuiPickersUtilsProvider>
}

export type CustomDatePickerControllerProps = Omit<CustomDatePickerProps, 'name' | 'onChange' | 'value'> &
    {
        name: string,
        control: Control,
    };

export function CustomDatePickerController({name, control, defaultValue, ...props}: CustomDatePickerControllerProps) {
    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({onChange, value}) => {
            const handleChange = (date: MaterialUiPickersDate | null) => {
                const parsed = date && date.isValid() ? date : null;
                return onChange(parsed);
            }
            return <CustomDatePicker
                {...props}
                onChange={handleChange}
                value={value ?? null}
            />;
        }}/>;
}
