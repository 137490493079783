import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Logo, {LogoProps} from './Logo';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {CircularProgress, Collapse} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {finalize, take} from "rxjs/operators";
import {Alert} from "@material-ui/lab";
import Copyright from "./Copyright";
import {useTranslation} from "react-i18next";
import {useCoreServicesContext} from "../services/context";

export interface LoginFormProps {
    logoProps: LogoProps,
}

const useStyles = makeStyles((theme) => ({
    container: {
        // paddingTop: theme.spacing(15),
    },
    title: {
        marginTop: theme.spacing(2),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        /* paddingBottom: theme.spacing(8),
         paddingLeft: theme.spacing(4),
         paddingRight: theme.spacing(4),*/
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    alert: {
        width: '100%',
        marginTop: theme.spacing(2),
        border: `1px solid ${theme.palette.error.dark}`
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        minHeight: theme.spacing(6),
        margin: theme.spacing(3, 0, 2),
    }
}));


export default function LoginForm({logoProps}: LoginFormProps) {
    const classes = useStyles();
    const {register, handleSubmit} = useForm();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState<string | null>(null);
    const {authService} = useCoreServicesContext();
    const onSubmit = handleSubmit(({username, password}) => {
        if (loading)
            return;
        if (!username || !password) {
            setLoginError(t("c.login.invalidUsernamePassword"));
            return;
        }
        setLoginError(null);
        setLoading(true);
        authService.login(username, password).pipe(
            take(1),
            // finalize(() => setLoading(false))
        ).subscribe(() => {
            // setLoginError(null);
        }, error => {
            console.error(`Error login: ${error}`);
            setLoading(false);
            setLoginError(t("c.login.invalidUsernamePassword"));
        });
    });

    return (
        <Container className={classes.container} component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Box mb={1}>
                    <Logo {...logoProps}/>
                </Box>
                <Typography className={classes.title} component="h1" variant="h5">
                    {t("c.login.connection")}
                </Typography>
                <form onSubmit={onSubmit} className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={t("c.login.username")}
                        name="username"
                        autoComplete="username"
                        inputRef={register({
                            required: true,
                        })}
                        InputLabelProps={{required: false}}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t("c.login.password")}
                        type="password"
                        id="password"
                        inputRef={register({
                            required: true,
                        })}
                        InputLabelProps={{required: false}}
                        autoComplete="current-password"
                    />
                    <Collapse in={!!loginError} mountOnEnter unmountOnExit>
                        <Alert severity="error" className={classes.alert}>{loginError}</Alert>
                    </Collapse>
                    <Button
                        // disabled={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        // startIcon={loading ? }
                    >
                        {loading ? <CircularProgress size={20} color="inherit"/> : t("c.login.submit")}
                    </Button>
                </form>
            </div>
            <Box mt={5}>
                <Copyright/>
            </Box>
        </Container>
    );
}
