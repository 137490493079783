import {FilterBaseService} from "../../core/services/base/filter.service";
import {ProductFilter, ProductSubFilter} from "../types/product-filter.types";
import {Product} from "../types/product.types";

export class ProductsFilterService extends FilterBaseService<Product, ProductFilter, ProductSubFilter> {
    constructor() {
        super();
        this.searchQuery$.subscribe((query) => this.setFilter({
            title: query
        }));
        this.setFilter({
           
        });
    }

    public includes(product: Product, subFilter: ProductSubFilter): boolean {
        return this.includesString(product.contractNumber, subFilter.contractNumber)
            && this.includesEnumItem(product.producerDistributor, subFilter.producerDistributor)
            && this.includesEnumItem(product.productType, subFilter.productType)
            && this.includesEnumItem(product.type, subFilter.type)
            && (!subFilter.type || product.type?.title.toLowerCase().includes(subFilter.type.title.toLowerCase())) as boolean
            && (!subFilter.productType || product.productType?.title.toLowerCase().includes(subFilter.productType.title.toLowerCase())) as boolean
    }
}
