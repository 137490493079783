import {CoreTranslation} from "./fr";

export const CORE_IT: CoreTranslation = {
    // TODO: Translation pending for login
    login: {
        connection: "Connexion",
        username: "Nom d'utilisateur",
        password: "Mot de passe",
        invalidUsernamePassword: "Veuillez saisir un nom d'utilisateur et un mot de passe valide.",
        submit: "Se Connecter",
    },
    common: {
        cancel: "Annulla",
        edit: "Modificare",
        ok: "OK",
        delete: "Cancella",
        save: "Salva",
        loading: "Caricamento",
        saving: "Registrazione in corso",
        savingFinished: "Registrazione completata",
        savingFailed: "Registrazione fallita",
        select: "Selezionare",
        noResultFound: "Nessun risultato trovato",
        yes: "Si",
        no: "No",
        products: "Prodotti",
        noOption: "Nessuna opzione",
        notFilled: "Non specificato",
        today: "Oggi",
        invalidDate: "Dati non validi",
        titleContains: "Titolo contenente",
        perPage: 'Per pagina',
        of: 'su'
    },
}
