import {BehaviorSubject, Observable} from "rxjs";
import {shareReplay} from "rxjs/operators";

export interface LoadingScreenStatus {
    loading: boolean;
    message?: string;
}

export class LoadingScreenService {
    private loadingSubject: BehaviorSubject<LoadingScreenStatus> = new BehaviorSubject<LoadingScreenStatus>({
        loading: false
    });
    public loading$: Observable<LoadingScreenStatus> = this.loadingSubject.asObservable().pipe(shareReplay(1));

    public setLoading(status: LoadingScreenStatus) {
        this.loadingSubject.next(status);
    }
}
