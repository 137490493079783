import React, {useContext} from "react";
import {ToastNotificationsService} from "./toast-notifications.service";
import {LoadingScreenService} from "./loading-screen.service";
import {SaveStatusService} from "./save-status.service";
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {DataService} from "./atomic/data.service";

export const CORE_SERVICES: CoreServices = initServices();

export const CoreServicesContext: React.Context<CoreServices> = React.createContext<CoreServices>(CORE_SERVICES);

export function useCoreServicesContext() {
    return useContext<CoreServices>(CoreServicesContext);
}

interface CoreServices {
    apiService: ApiService;
    authService: AuthService;
    toastNotificationsService: ToastNotificationsService;
    saveStatusService: SaveStatusService;
    loadingScreenService: LoadingScreenService;
    dataService: DataService;
}

function initServices(): CoreServices {
    const loadingScreenService = new LoadingScreenService();
    const toastNotificationsService = new ToastNotificationsService(loadingScreenService);
    const apiService = new ApiService(toastNotificationsService);
    const authService = new AuthService(apiService);
    const saveStatusService = new SaveStatusService(loadingScreenService);
    const dataService = new DataService(
        apiService,
        toastNotificationsService,
        loadingScreenService,
        saveStatusService
    );

    return {
        toastNotificationsService,
        loadingScreenService,
        saveStatusService,
        apiService,
        authService,
        dataService,
    }
}
