import {Theme, Tooltip, TooltipProps} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '14px',
        padding: '15px'
    },
}));

export type TextTooltipProps = TooltipProps;

export default function TextTooltip(props: TextTooltipProps) {
    const classes = useStyles();

    return <Tooltip arrow classes={classes} {...props}/>;
}
