import {Box, Slide, Snackbar} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, {useState} from "react";
import {useObservable} from "rxjs-hooks";
import {delay, tap} from "rxjs/operators";
import {useCoreServicesContext} from "../services/context";

export default function ToastNotification() {
    const [snackOpen, setSnackOpen] = useState(false);
    const {toastNotificationsService} = useCoreServicesContext();
    const notification = useObservable(() => toastNotificationsService.toastNotification$.pipe(
        delay(200),
        tap(() => setSnackOpen(true)),
    ));
    const handleClose = () => {
        setSnackOpen(false);
    };
    return <Snackbar
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
        }}
        autoHideDuration={notification?.delay ?? 3000}
        TransitionComponent={(props => <Slide {...props} direction="up"/>)}
        open={snackOpen}
        onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled"
                  onClose={handleClose} severity={notification?.type}>
            <Box>{notification?.message}</Box>
            {notification?.description && <Box component="small" whiteSpace="pre">{notification?.description}</Box>}
        </MuiAlert>
    </Snackbar>;
}
