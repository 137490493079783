import {BehaviorSubject, combineLatest, Observable, Subject} from "rxjs";
import {map, shareReplay, take} from "rxjs/operators";
import {isEmpty} from "../../utils/checking";
import {EnumItem, ToFillEnumItemCode} from "../../types/common";

export abstract class FilterBaseService<TEntity, TFilter = {}, TSubFilter = {}> {
    protected subFilterSubject = new BehaviorSubject<TSubFilter>({} as TSubFilter);
    protected filterSubject = new BehaviorSubject<TFilter>({} as TFilter);
    private visibleSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private searchQuerySubject: Subject<string> = new Subject();
    public visible$: Observable<boolean> = this.visibleSubject.asObservable().pipe(shareReplay(1));
    public searchQuery$: Observable<string> = this.searchQuerySubject.asObservable().pipe(shareReplay(1));
    public filter$: Observable<TFilter> = this.filterSubject.pipe(shareReplay(1));
    public subFilter$: Observable<TSubFilter> = this.subFilterSubject.pipe(shareReplay(1));
    public filterCount$: Observable<number> = combineLatest([this.filter$, this.subFilter$]).pipe(
        map(([filter, subFilter]) => Object.entries({...filter, ...subFilter})
            .filter(([key, value]) => {
                return !isEmpty(value);
            }).length),
    );

    constructor() {
        // this.filter$.subscribe();
        // this.subFilter$.subscribe();
    }

    public setSearchQuery(query: string) {
        this.searchQuerySubject.next(query);
    }

    public toggle(value: boolean) {
        this.visibleSubject.next(value);
    }

    public setFilter(items: TFilter) {
        this.filter$.pipe(
            take(1)
        ).subscribe((filter) => {
            this.filterSubject.next({...filter, ...items});
        })
    }

    public setSubFilter(items: TSubFilter) {
        this.subFilter$.pipe(take(1))
            .subscribe((subFilter) => {
                this.subFilterSubject.next({...subFilter, ...items})
            });
    }

    public clear() {
        this.subFilterSubject.next({} as TSubFilter);
        this.filterSubject.next({} as TFilter);
    }

    public abstract includes(entity: TEntity, subFilter: TSubFilter): boolean;

    protected getNumbersInterval(query: string): number[] {
        const interval: number[] = [];
        const intervals = query.split(',');
        intervals.forEach((content, index) => {
            if (content.includes('-')) {
                const innerIntervals = content.split('-');
                const leftNumber = innerIntervals[0];
                const rightNumber = innerIntervals[1];
                if (!leftNumber || !rightNumber || isNaN(+leftNumber) || isNaN(+rightNumber)) {
                    return;
                }
                for (let i = +leftNumber; i <= +rightNumber; i++) {
                    interval.push(i);
                }
                return;
            } else if (isNaN(+content)) {
                return;
            } else {
                interval.push(+content);
            }
        });
        return interval;
    }

    protected includesEnumItem(productEnumItem: EnumItem | null, filterEnumItem?: EnumItem | null) {
        const toFillEnumItemCode: ToFillEnumItemCode = '__toFill';
        return !filterEnumItem || ((filterEnumItem.code === toFillEnumItemCode && !productEnumItem) || (productEnumItem?.id == filterEnumItem.id));
    }

    protected includesString(productString: string, filterString?: string) {
        return !filterString?.length || productString?.toLowerCase().includes(filterString.toLowerCase());
    }
}
