import Typography from "@material-ui/core/Typography";
import noResultSvg from "../../assets/no-results-illustration.svg";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noResult: {
            width: '100%',
            height: 450,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        noResultSvg: {
            width: 280,
            marginBottom: 35,
        },
        noResultText: {
            fontWeight: 400
        },
    }),
);

export default function DataTableNoResult() {
    const classes = useStyles();
    return <div className={classes.noResult}>
        <img className={classes.noResultSvg} src={noResultSvg}/>
        <Typography variant="h6" className={classes.noResultText} color="textSecondary" component="div">
            Aucun résultat trouvé
        </Typography>
    </div>;
}
