import {Box, Typography} from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, {ReactNode} from "react";

export interface FormPageHeaderProps {
    title: string | ReactNode,
    leftIcon?: ReactNode;
    exitIcon?: ReactNode;
    onExitClick: () => void
}

export default function FormPageHeader({title, onExitClick, leftIcon, exitIcon}: FormPageHeaderProps) {
    return <Box display="flex" alignItems="center" lineHeight={0} width="100%">
        <Box mr={1.5} display="flex" alignItems="center">{leftIcon || <ListAltIcon/>}</Box>
        <Box fontSize="h6.fontSize" lineHeight={1}>{title}</Box>
        <Box ml="auto" display="flex" alignItems="center" mr="-12px">
            <IconButton type={"button"} onClick={() => onExitClick()}
                        color="inherit">
                {exitIcon || <ArrowBackIcon/>}
            </IconButton>
        </Box>
    </Box>
}
