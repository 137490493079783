import {DeliverablesService} from "./deliverables.service";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {map, shareReplay, take, withLatestFrom} from "rxjs/operators";
import {ApiDeliverableSave, Deliverable, DeliverableForm} from "../types/deliverable.types";
import {DataService} from "../../core/services/atomic/data.service";
import {ApiService} from "../../core/services/api.service";
import {DataTableService} from "../../core/components/DataTable/service";

export class DeliverablesFormService {
    private isOpenSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private selectedLinesSubject = new Subject<(number | string)[]>();
    private dataTableService?: DataTableService<Deliverable>;
    private changesSubject = new BehaviorSubject<Deliverable[]>([]);
    public changes$: Observable<Deliverable[]> = this.changesSubject.pipe(shareReplay(1));
    public changesCount$: Observable<number> = this.changes$.pipe(
        map(changes => changes.length),
        shareReplay(1)
    );
    private selectedLines$: Observable<(number | string)[]> = this.selectedLinesSubject.pipe(shareReplay(1));
    public selectionCount$: Observable<number> = this.selectedLines$.pipe(
        map(selection => selection.length),
        shareReplay(1)
    );
    public isOpen$: Observable<boolean> = this.isOpenSubject.pipe(shareReplay(1));

    constructor(
        private apiService: ApiService,
        private dataService: DataService,
        private deliverablesService: DeliverablesService) {
    }

    public register(service: DataTableService<Deliverable>) {
        this.dataTableService = service;
    }

    public reset() {
        if (this.dataTableService) {
            this.dataTableService.clearChanges();
        }
    }

    public setChanges(deliverables: Deliverable[]) {
        this.changesSubject.next(deliverables);
    }

    public open() {
        this.isOpenSubject.next(true);
    }

    public close() {
        this.isOpenSubject.next(false);
    }

    public save(form?: DeliverableForm) {
        this.dataService.saveMany(
            this.saveProducts(form)
        ).subscribe(() => {
            this.close();
            this.deliverablesService.refresh();
        });
    }

    public selectLines(selection: (string | number)[]) {
        this.selectedLinesSubject.next(selection);
    }

    private saveProducts(bulkSaveForm?: DeliverableForm): Observable<any> {
        const put = (id: number | string, expectedDeliverableReceptionDate: Deliverable['expectedDeliverableReceptionDate']) => this.apiService.put({
            path: `/deliverable/${id}`,
            data: {
                tp_receipt_expected_date: expectedDeliverableReceptionDate?.format('YYYY-MM-DD') ?? ''
            } as ApiDeliverableSave
        });

        if (bulkSaveForm) {
            return this.selectedLines$.pipe(
                withLatestFrom(this.changes$),
                take(1),
                map(([selectedLines, changes]) => {
                    return [
                        ...selectedLines.map((id) => put(id, bulkSaveForm.expectedDeliverableReceptionDate)),
                        ...changes.filter(c => !selectedLines.includes(c.id))
                            .map(c => put(c.id, c.expectedDeliverableReceptionDate))
                    ]
                }),
            );
        } else {
            return this.changes$.pipe(
                take(1),
                map(changes => changes.map(c => put(c.id, c.expectedDeliverableReceptionDate)))
            )
        }


    }

}
