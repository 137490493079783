import {useObservable} from "rxjs-hooks";
import {Paper, Table, TableContainer} from "@material-ui/core";
import DataTableHead from "./DataTableHead";
import DataTableBody from "./DataTableBody";
import DataTablePagination from "./DataTablePagination";
import React from "react";
import {useDataTableContext} from "./context";
import DataTableNoResult from "./DataTableNoResult";
import {DataTableToolbar} from "./DataTableToolbar";

export default function DataTableContent<T>() {
    const service = useDataTableContext<T>();
    const noResult = useObservable(() => service.noResult$, false);

    return <Paper style={{overflow: 'hidden'}}>
        <TableContainer>
            <DataTableToolbar/>
            {
                noResult ? <DataTableNoResult/> : <React.Fragment>
                    <Table>
                        <DataTableHead/>
                        <DataTableBody/>
                    </Table>
                    <DataTablePagination/>
                </React.Fragment>
            }
        </TableContainer>
    </Paper>
}
