import {Backdrop, Box, CircularProgress} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useObservable} from "rxjs-hooks";
import {useServicesContext} from "../../features/services/context";
import {LoadingScreenStatus} from "../services/loading-screen.service";
import {useCoreServicesContext} from "../services/context";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 90000,
        color: '#fff',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: '100vh',
    },
}));

export function LoadingScreen() {
    const classes = useStyles();
    const {loadingScreenService} = useCoreServicesContext();
    const loadingStatus = useObservable<LoadingScreenStatus>(() => loadingScreenService.loading$
        , {
        loading: false
    });
    return <Backdrop className={classes.backdrop}
                     open={loadingStatus.loading}>
        <CircularProgress color="inherit"/>
        <Box mt={2}>
            {loadingStatus.message ?? 'Chargement'}
        </Box>
    </Backdrop>
}
