import LoginForm from "../../../core/components/LoginForm";
import React from "react";
import {Box} from "@material-ui/core";

export default function LoginPage() {
    return <Box display="flex" alignItems="center" height="100%">
        <LoginForm logoProps={{
            src: './favicon96.png',
            // width: '70px'
        }}/>
    </Box>;
}
