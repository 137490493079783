import i18n, {Resource} from "i18next";
import {initReactI18next} from "react-i18next";
import detector from "i18next-browser-languagedetector";
import {CORE_FR} from "./core/translations/fr";
import {CORE_IT} from "./core/translations/it";
import {FEATURES_FR} from "./features/translations/fr";
import {FEATURES_IT} from "./features/translations/it";

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

            // keys or params to lookup language from
            lookupQuerystring: 'language',
        },
        resources: {
            fr: {
                translation: {
                    c: CORE_FR,
                    f: FEATURES_FR
                }
            },
            it: {
                translation: {
                    c: CORE_IT,
                    f: FEATURES_IT
                }
            }
        },
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
