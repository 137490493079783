import {Box, Toolbar, Typography, useTheme} from "@material-ui/core";
import React from "react";

export default function CustomToolbar({children, title, dark}: {children?: React.ReactNode, title?: string, dark?: boolean}) {
    const theme = useTheme();
    const style: React.CSSProperties = {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    };

    if(dark) {
        style.backgroundColor = theme.palette.primary.main;
        style.color = theme.palette.primary.contrastText;
    }

    return <Toolbar style={style}>
        <Box fontSize={theme.typography.h6.fontSize}
             whiteSpace="noWrap"
             fontWeight={dark ? theme.typography.fontWeightRegular : theme.typography.h6.fontWeight}
        >{title}</Box>
        {children}
    </Toolbar>
}
