import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from "rxjs";
import {distinctUntilChanged, map, shareReplay, take, tap, withLatestFrom} from "rxjs/operators";
import {
    DataState,
    DataTableColumn,
    DataTableColumnRowInfo, DataTableProps, DataTableServiceHandlers,
    DataTableToolbarProps, IdGetter, MultiSelectType,
    PaginationOptions
} from "./types";
import {sort} from "./utils";

import i18n from "../../../i18n/i18n";

export class DataTableService<T = any> {
    private selection = new Map<string | number, T>();
    private changes = new Map<string | number, T>();
    private subscription = new Subscription();
    private handlersSubscription = new Subscription();
    private changesSubject = new Subject<Map<string | number, T>>();
    private columnsRowInfoSubject = new Subject<DataTableColumnRowInfo<T>[]>();
    private columnsSubject = new Subject<DataTableColumn<T>[]>();
    private dataSubject = new Subject<DataState<T>>();
    private multiSelectSubject = new BehaviorSubject<MultiSelectType<T>>(false);
    private forceRefreshRowsSubject = new BehaviorSubject<number>(0);
    private selectionSubject = new Subject<Map<string | number, T>>();
    private paginationSubject = new BehaviorSubject<PaginationOptions>({
        rowsPerPage: 100,
        page: 0,
        labelRowsPerPage: i18n.t('c.common.perPage'),
        labelDisplayedRowsMiddle: i18n.t('c.common.of'),
        rowsPerPageOptions: [5, 10, 25, 50, 100]
    });
    private toolbarPropsSubject = new BehaviorSubject<DataTableToolbarProps>({
        title: i18n.t('c.common.products')
    })
    private rowClickSubject = new Subject<T>();
    private hoverSubject = new BehaviorSubject<boolean>(false);
    private data$: Observable<DataState<T>> = this.dataSubject.pipe(
        tap((value) => {
            if (this.selection.size) {
                this.selection.clear();
                this.selectionSubject.next(this.selection);
            }
            if (this.changes.size) {
                this.clearChanges();
            }
            this.setPagination({page: 0});
        }),
        shareReplay(1)
    );
    public hover$ = this.hoverSubject.pipe(shareReplay(1));
    public rowClick$: Observable<T> = this.rowClickSubject.pipe(shareReplay(1));
    public toolbarProps$: Observable<DataTableToolbarProps> = this.toolbarPropsSubject.pipe(shareReplay(1));
    public pagination$: Observable<PaginationOptions> = this.paginationSubject.pipe(shareReplay(1));
    public rows$: Observable<DataState<T>> = this.data$;
    public multiSelect$: Observable<MultiSelectType<T>> = this.multiSelectSubject.pipe(shareReplay(1));
    public columnsRowInfo$: Observable<DataTableColumnRowInfo<T>[]> = this.columnsRowInfoSubject.pipe(shareReplay(1));
    public columns$: Observable<DataTableColumn<T>[]> = this.columnsSubject.pipe(shareReplay(1));
    public rowsCount$: Observable<number> = this.rows$.pipe(
        map(state => state.data?.length ?? 0),
        distinctUntilChanged()
    );
    public changes$: Observable<Map<string | number, T>> = this.changesSubject.pipe(shareReplay(1));
    public pageState$: Observable<DataState<T>> = this.initPageRows();
    public pageRowsCount$: Observable<number> = this.pageState$.pipe(
        map(state => state.data.length),
        shareReplay(1),
    );
    public loading$: Observable<boolean> = this.data$.pipe(
        map(state => !!state.loading),
        distinctUntilChanged(),
        shareReplay(1)
    );
    public noResult$: Observable<boolean> = this.data$.pipe(
        map(state => !state.loading && state.data?.length === 0),
        distinctUntilChanged(),
    );
    public selection$: Observable<Map<string | number, T>> = this.selectionSubject.pipe(shareReplay(1));
    public selectionCount$: Observable<number> = this.selection$.pipe(
        map(s => s.size),
        distinctUntilChanged(),
        shareReplay(1)
    );
    public forceRefreshRows$ = this.forceRefreshRowsSubject.asObservable();

    public getId: IdGetter<T> = (e) => (e as any).id;

    public subscribeTo(data$: Observable<DataState<T>>) {
        this.subscription.add(data$.subscribe(
            (state) => this.dataSubject.next(state)
        ));
    }

    public setColumns(columns: DataTableColumn<T>[]) {
        this.columnsSubject.next(columns);
        this.columnsRowInfoSubject.next(columns);
    }

    public setPagination(newOptions: Partial<PaginationOptions>) {
        this.pagination$.pipe(
            take(1),
        ).subscribe((options) => {
            this.paginationSubject.next({...options, ...newOptions});
        });
    }

    public setIdGetter(getter: IdGetter<T>) {
        this.getId = getter;
    }

    public initHandlers({onSelectChange, onRowClick, onDataChange, onServiceCreated}: DataTableServiceHandlers<T>) {
        if (this.handlersSubscription) {
            this.handlersSubscription.unsubscribe();
        }
        this.handlersSubscription = new Subscription();
        if (onServiceCreated) {
            onServiceCreated(this);
        }

        if (onSelectChange) {
            this.handlersSubscription.add(
                this.selection$.subscribe(
                    (selection) => onSelectChange(Array.from(selection.keys()) as (string | number)[])
                )
            );
        }
        if (onRowClick) {
            this.handlersSubscription.add(
                this.rowClick$.subscribe((row) => onRowClick(row))
            );
            this.hoverSubject.next(true);
        } else {
            this.hoverSubject.next(false);
        }

        if (onDataChange) {
            this.handlersSubscription.add(
                this.changes$.subscribe((changes) => onDataChange(Array.from(changes.values()) as T[]))
            )
        }
    }

    public setMultiSelect(value: MultiSelectType<T>) {
        this.multiSelectSubject.next(value);
    }

    public setToolbarProps(toolbar: DataTableToolbarProps) {
        this.toolbarPropsSubject.next(toolbar);
    }

    public changePage(page: number) {
        this.setPagination({page});
    }

    public changeRowsPerPage(rowsPerPage: number) {
        this.setPagination({rowsPerPage, page: 0});
    }

    public sort(column: DataTableColumn<T>) {
        this.columns$.pipe(
            take(1),
        ).subscribe((columns) => {
            const newColumn = {...column};
            const ranks = columns.map(c => c.orderRank).filter(c => !!c) as number[];
            const highestCurrentRank = ranks.length ? Math.max(...ranks) : 0;
            switch (column.order) {
                case "asc":
                    newColumn.order = "desc";
                    break;
                case "desc":
                    newColumn.order = undefined;
                    newColumn.orderRank = undefined;
                    break;
                default:
                    newColumn.order = "asc";
                    newColumn.orderRank = highestCurrentRank + 1;
            }
            const newColumns = columns.map((c) => {
                if (c.id === newColumn.id) {
                    return newColumn;
                }
                if (c.orderRank && !newColumn.orderRank && column.orderRank && c.orderRank > column.orderRank) {
                    c.orderRank = c.orderRank - 1;
                }
                return c;
            });
            this.columnsSubject.next(newColumns);
        })
    }

    public selectToggle(row: T, selected: boolean) {
        const key = this.getId(row);
        if (selected) {
            this.selection.set(key, row);
        } else {
            this.selection.delete(key);
        }
        this.selectionSubject.next(this.selection);
    }

    public isSelected(row: T): boolean {
        return this.selection.has(this.getId(row));
    }

    public clickRow(row: T) {
        return this.rowClickSubject.next(row);
    }

    public selectAllToggle(selected: boolean) {
        this.rows$.pipe(
            withLatestFrom(this.multiSelect$),
            take(1),
        ).subscribe(([state, multiSelect]) => {
            if (selected) {
                state.data.forEach((row) => {
                    if (multiSelect && typeof multiSelect === 'function' && !multiSelect(row))
                        return;
                    this.selection.set(this.getId(row), row);
                });
            } else {
                this.selection.clear();
            }
            this.forceRefreshRows();
            this.selectionSubject.next(this.selection);
        });

    }

    public setChange(row: T) {
        this.data$.pipe(
            withLatestFrom(this.columnsRowInfo$),
            take(1),
        ).subscribe(([{data}, columns]) => {
            this.setRowChange(row, data, columns);
            this.changesSubject.next(this.changes);
        });
    }

    public setChanges(rows: T[], clearSelection?: boolean) {
        this.data$.pipe(
            withLatestFrom(this.columnsRowInfo$),
            take(1),
        ).subscribe(([{data}, columns]) => {
            rows.forEach((row) => this.setRowChange(row, data, columns));
            this.changesSubject.next(this.changes);
            if (clearSelection) {
                this.selectAllToggle(false);
            } else {
                this.forceRefreshRows();
            }
        });
    }

    public getChange(row: T) {
        return this.changes.get(this.getId(row));
    }

    public clearChanges() {
        this.changes.clear();
        this.changesSubject.next(this.changes);
        this.forceRefreshRows();
    }

    public dispose() {
        this.subscription.unsubscribe();
        this.handlersSubscription.unsubscribe();
        this.columnsRowInfoSubject.unsubscribe();
        this.columnsSubject.unsubscribe();
        this.dataSubject.unsubscribe();
        this.multiSelectSubject.unsubscribe();
        this.forceRefreshRowsSubject.unsubscribe();
        this.selectionSubject.unsubscribe();
        this.paginationSubject.unsubscribe();
        this.toolbarPropsSubject.unsubscribe();
        this.rowClickSubject.unsubscribe();
        this.hoverSubject.unsubscribe();
    }

    private forceRefreshRows() {
        this.forceRefreshRows$.pipe(
            take(1),
        ).subscribe((count) => {
            this.forceRefreshRowsSubject.next(count + 1);
        });
    }

    private setRowChange(row: T, data: T[], columns: DataTableColumnRowInfo<T>[]) {
        const id = this.getId(row);
        const record = data.find(r => this.getId(r) == id)!;
        const isDifferent = columns.filter(c => !!c.comparator).some((column) => {
            return !column.comparator!(record, row)
        });
        if (isDifferent) {
            this.changes.set(id, row);
        } else {
            this.changes.delete(id);
        }
    }

    private initPageRows() {
        return combineLatest([
            this.rows$,
            this.pagination$.pipe(
                map(({rowsPerPage, page}) => ({rowsPerPage, page})),
                distinctUntilChanged((a, b) => a.page === b.page && a.rowsPerPage === b.rowsPerPage),
            ),
            this.columns$,
        ]).pipe(
            map(([{loading, data}, {page, rowsPerPage}, columns]) => ({
                loading,
                data: loading ? [] : sort(data, columns).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            })),
            shareReplay(1)
        )
    }
}
