import React from "react";
import ProductDetailsFields from "./ProductDetailsFields";
import {Box} from "@material-ui/core";
import DeliverablesDataTableContent from "./Deliverables/DeliverablesDataTable/DeliverablesDataTableContent";
import DeliverablesFormDialog from "./Deliverables/DeliverablesFormDialog/DeliverablesFormDialog";

export default function ProductDetailsContent() {
    return <React.Fragment>
        <ProductDetailsFields/>
        <Box mt={4}>
            <DeliverablesDataTableContent/>
            <DeliverablesFormDialog/>
        </Box>
    </React.Fragment>
}
