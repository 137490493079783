import React from 'react';
import {Theme, ThemeProvider} from '@material-ui/core/styles';
import {CoreServicesContext, CORE_SERVICES} from "../services/context";
import defaultTheme from "../theme/theme";
import ToastNotification from "./ToastNotification";
import {LoadingScreen} from "./LoadingScreen";


function Core({children, theme}: { children: React.ReactNode, theme?: Theme }) {
    return <CoreServicesContext.Provider value={CORE_SERVICES}>
        <ThemeProvider theme={theme ?? defaultTheme}>
            {children}
            <LoadingScreen/>
            <ToastNotification/>
        </ThemeProvider>
    </CoreServicesContext.Provider>;
}

export default Core;
