import {useDataTableContext} from "./context";
import {useObservable} from "rxjs-hooks";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect} from "react";
import DataTableRow from "./DataTableRow";
import {map, tap, withLatestFrom} from "rxjs/operators";
import DataTableLoadingRows from "./DataTableLoadingRows";
import {combineLatest} from "rxjs";

export default function DataTableBody<T>() {
    const {pagination$, columnsRowInfo$, pageState$, multiSelect$, getId, forceRefreshRows$, hover$} = useDataTableContext<T>();
    const columns = useObservable(() => columnsRowInfo$);
    const state = useObservable(() => combineLatest([
        forceRefreshRows$,
        pageState$
    ]).pipe(
        withLatestFrom(combineLatest([
            pagination$,
            multiSelect$,
            hover$
        ])),
        map(([[refreshToken, pageState], [pagination, multiSelect, hover]]) => ({
            ...pageState,
            pagination,
            multiSelect,
            refreshToken,
            hover
        })),
    ));

    return <TableBody>
        {(state?.loading) && columns &&
        <DataTableLoadingRows multiSelect={state?.multiSelect}
                              columns={columns}
                              rowsPerPage={state?.pagination.rowsPerPage ?? 25}/>}
        {state && !state.loading && columns && state.data.map((row, index) =>
            <DataTableRow multiSelect={state.multiSelect}
                          columns={columns}
                          hover={state.hover}
                          key={`${getId(row)}_${state.refreshToken}`}
                          row={row}/>)}
    </TableBody>
}
