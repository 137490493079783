import {useDataTableContext} from "./context";
import TableHead from "@material-ui/core/TableHead";
import React, {useEffect} from "react";
import TableRow from "@material-ui/core/TableRow";
import {Badge, TableCell, Tooltip, withStyles} from "@material-ui/core";
import {useObservable} from "rxjs-hooks";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {DataTableColumn} from "./types";
import DataTableHeadSelectBox from "./DataTableHeadSelectBox";
import {tap} from "rxjs/operators";

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            opacity: .75,
            transition: 'none',
            // right: -3,
            top: "-7px",
            transform: 'scale(.75) translate(50%, -50%)'
            // minWidth: '10px'
            // border: `2px solid ${theme.palette.background.paper}`,
            // padding: '0 4px',
        },
    }),
)(Badge);
export default function DataTableHead<T>() {
    const service = useDataTableContext<T>();
    const loading = useObservable(() => service.loading$, true);
    const columns = useObservable(() => service.columns$, []);
    const multiSelect = useObservable(() => service.multiSelect$);
    const createSortHandler = (column: DataTableColumn<T>) => () => {
        service.sort(column);
    };

    return <TableHead>
        <TableRow>
            {
                multiSelect && <TableCell>
                    <DataTableHeadSelectBox/>
                </TableCell>
            }
            {
                columns.map((column, index) =>
                    <TableCell key={index} {...column.headTableCellProps}>
                        <TableSortLabel
                            disabled={loading}
                            hideSortIcon={true}
                            active={!!column.order}
                            direction={column.order}
                            onClick={createSortHandler(column)}
                        >
                            {
                                column.shortLabel ?
                                    <Tooltip title={column.label}>
                                        <span>{column.shortLabel}</span>
                                    </Tooltip> : column.label
                            }
                        </TableSortLabel>
                        <StyledBadge
                            color="secondary"
                            invisible={!column.order}
                            badgeContent={column.orderRank}>
                        </StyledBadge>
                        {/*{column.label}*/}
                    </TableCell>
                )
            }
        </TableRow>
    </TableHead>
}
