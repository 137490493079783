import {ApiError, ApiService} from "../api.service";
import {ToastNotificationsService} from "../toast-notifications.service";
import {LoadingScreenService} from "../loading-screen.service";
import {SaveStatusService} from "../save-status.service";
import {Change} from "../../types/common";
import {combineLatest, concat, defer, EMPTY, merge, Observable, of, zip} from "rxjs";
import {catchError, endWith, switchMap, take, tap} from "rxjs/operators";
import i18n from "../../../i18n/i18n";

export class DataService {
    private savingMsg = i18n.t("c.common.saving");
    private savingFailedMsg = i18n.t("c.common.savingFailed");
    private savingFinishedMsg = i18n.t("c.common.savingFinished");

    constructor(
        private apiService: ApiService,
        private toastNotificationsService: ToastNotificationsService,
        private loadingScreenService: LoadingScreenService,
        private saveStatusService: SaveStatusService,
    ) {
    }

    public saveTable<T extends { id: number | string }, K>(path: string,
                                                           items: Change<T>[],
                                                           getData: (payload: T) => K,
    ): Observable<any> {
        if (!items.length)
            return of(undefined);
        let counter = 0;
        return zip(...items.map((item) => {
            // console.log('creating $ for updating data table item', item);
            return defer(() => {
                const change = item.__changeType!;
                if (change == 'add' || change == 'edit') {
                    const data = getData(item);
                    if (change == 'add') {
                        return this.apiService.post({
                            path: `${path}`,
                            data,
                        });
                    } else if (change == 'edit') {
                        return this.apiService.put({
                            path: `${path}${item.id}`,
                            data
                        });
                    }
                }
                if (change == 'delete') {
                    return this.apiService.delete({
                        path: `${path}${item.id}`,
                    });
                }
                // return EMPTY;
            }).pipe(
                tap(() => {
                    counter++;
                    this.saveStatusService.setSaveStatus({
                        progress: true,
                        counter: counter,
                        message: counter == 0 ? this.savingMsg : `${this.savingMsg} (${counter}/${items.length})`,
                    });
                })
            );
        }));
    }

    public save<T>(source: Observable<T>, throwError?: boolean): Observable<any> {
        return defer(() => {
            this.handleSaveStart();
            return source.pipe(
                take(1),
                catchError(error => {
                    this.handleSaveError(error);
                    if (!throwError)
                        return EMPTY;
                    throw error;
                }),
                tap(() => this.handleSaveSuccess()),
            );
        })
    }

    public saveMany<T>(source: Observable<Observable<T>[]>, throwError?: boolean): Observable<any> {
        return defer(() => {
            let counter = 0;
            this.handleSaveStart();
            return source.pipe(
                switchMap((list) => combineLatest(list.map(
                    source => source.pipe(
                        tap(() => {
                            counter++;
                            this.saveStatusService.setSaveStatus({
                                progress: true,
                                counter: counter,
                                message: `${this.savingMsg} (${counter}/${list.length})`,
                            });
                        })
                    )
                )).pipe(
                    take(1),
                    catchError(error => {
                        this.handleSaveError(error);
                        if (!throwError)
                            return EMPTY;
                        throw error;
                    }),
                    tap(() => this.handleSaveSuccess())
                ))
            )
        });
    }

    public handleSaveStart() {
        this.saveStatusService.setSaveStatus({
            progress: true,
            message: this.savingMsg
        });
    }

    public handleSaveError(error: any) {
        const errMessage = error?.statusText || JSON.stringify(error);
        this.saveStatusService.setSaveStatus({
            progress: false,
            status: "error",
            message: this.savingFailedMsg,
            error: errMessage
        });
        if (error instanceof ApiError) {
            this.toastNotificationsService.apiErrorNotify(error, {
                message: this.savingFailedMsg
            });
        } else {
            this.toastNotificationsService.notify({
                type: "error",
                message: this.savingFailedMsg,
                description: errMessage
            });
        }
        console.error(`Saving failed`, error);
    }

    public handleSaveSuccess() {
        this.saveStatusService.setSaveStatus({
            progress: false,
            status: "success",
            message: this.savingFinishedMsg,
        });
        this.toastNotificationsService.notify({
            type: "success",
            message: this.savingFinishedMsg,
        });
    }

    // public getIdFromPayload(payload: )
}
