import AppBar, {AppBarProps} from "@material-ui/core/AppBar";
import Toolbar, {ToolbarProps} from "@material-ui/core/Toolbar";
import React, {ReactNode} from "react";
import {withStyles} from "@material-ui/core/styles";
import {Box, BoxProps, Paper} from "@material-ui/core";

const GAP = 5;

export const StyledToolbar = withStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(GAP),
        paddingRight: theme.spacing(GAP),
    }
}))(Toolbar);

export interface ShellProps {
    toolbarContent: ReactNode,
    toolbarAppBarProps?: AppBarProps,
    toolbarProps?: ToolbarProps,
    mainContent: ReactNode,
    drawer?: ReactNode,
    mainContentProps?: BoxProps;
}


export default function Shell({mainContent, toolbarContent, drawer, mainContentProps, toolbarProps, toolbarAppBarProps}: ShellProps) {
    return <React.Fragment>
        <Box>
            <AppBar position="sticky" {...toolbarAppBarProps}>
                <StyledToolbar {...toolbarProps}>
                    {toolbarContent}
                </StyledToolbar>
            </AppBar>
            {drawer}
        </Box>
        <Box p={GAP} bgcolor="#f5f5f5" {...mainContentProps}>
            {mainContent}
        </Box>
    </React.Fragment>
}
