import {Checkbox, TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {DataTableColumnRowInfo, DataTableRowProps} from "./types";
import {useDataTableContext} from "./context";
import {useTranslation} from "react-i18next";

export default function DataTableRow<T>({row, columns, multiSelect, hover}: DataTableRowProps<T>) {
    const service = useDataTableContext<T>();
    const [selected, setSelected] = useState(service.isSelected(row));
    const [change, setChange] = useState(service.getChange(row));
    const {t} = useTranslation();
    // useEffect(() => console.log('ROW - Render'));

    const handleChange = (row: T) => {
        setChange(row);
        service.setChange(row);
    }

    const parseColumn = (column: DataTableColumnRowInfo<T>, row: T) => {
        if (column.render) {
            return column.render(change ?? row, handleChange);
        }
        const value = row[column.id] as any;
        if (value === true) {
            return t('c.common.yes')
        } else if (value === false) {
            return t('c.common.no')
        } else if (['', null, undefined].includes(value)) {
            return '-'
        } else if (value.title) {
            return value.title
        } else {
            return value;
        }
    }

    const handleClick = () => {
        service.clickRow(row);
    };

    return <TableRow
        hover={hover}
        onClick={handleClick}
        selected={selected}>
        {
            !!multiSelect &&
            <TableCell
                style={{width: '15px'}}
                onClick={(e) => e.stopPropagation()}
                padding="checkbox">
                <Checkbox
                    disabled={(typeof multiSelect === "function" ? !multiSelect(row) : false)}
                    style={{padding: '9px', margin: '-9px'}}
                    onChange={(event) => {
                        const value = event.target.checked;
                        setSelected(value);
                        service.selectToggle(row, value)
                    }}
                    checked={selected}/>
            </TableCell>
        }
        {
            columns.map((column, index) =>
                <TableCell key={index} {...column.bodyTableCellProps}>
                    {parseColumn(column, row)}
                </TableCell>
            )
        }
    </TableRow>
}
