import React from "react";
import {useServicesContext} from "../../../services/context";
import {useObservable} from "rxjs-hooks";
import {map} from "rxjs/operators";
import FormPageHeader from "../../../../core/components/FormPageHeader";

export default function ProductDetailsHeader() {
    const {productsService} = useServicesContext();
    const title = useObservable(() => productsService.selected$.pipe(
        map(p => p?.title)), '');
    return <FormPageHeader title={title} onExitClick={() => productsService.deselect()}/>
}
