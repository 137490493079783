import React from "react";
import {useFormContext} from "react-hook-form";
import {useObservable} from "rxjs-hooks";
import {Alert} from "@material-ui/lab";
import {Box} from "@material-ui/core";
import {
    CustomDatePickerController,
    CustomDatePickerControllerProps
} from "../../../../../../core/components/CustomDatePicker";
import {DeliverableForm} from "../../../../../types/deliverable.types";
import {useServicesContext} from "../../../../../services/context";

const DeliverableDatePicker = (props: Omit<CustomDatePickerControllerProps, 'name'> & { name: keyof DeliverableForm }) =>
    <CustomDatePickerController {...props}/>;

export default function DeliverablesFormContent() {
    const {deliverablesFormService} = useServicesContext();
    const selected = useObservable(() => deliverablesFormService.selectionCount$);
    const {control} = useFormContext<DeliverableForm>();

    return <div>
        <DeliverableDatePicker
            name="expectedDeliverableReceptionDate"
            label="Prop. Date Liv. Prévue"
            control={control}
        />
        {
            !!selected && selected > 1 &&
            <Box mt={1} mb={-1}>
                <Alert severity="info">
                    <b>{selected}</b> livrables seront modifiés.
                </Alert>
            </Box>
        }
    </div>
}
