export const CORE_FR = {
    login: {
        connection: "Connexion",
        username: "Nom d'utilisateur",
        password: "Mot de passe",
        invalidUsernamePassword: "Veuillez saisir un nom d'utilisateur et un mot de passe valide.",
        submit: "Se Connecter",
    },
    common: {
        cancel: "Annuler",
        edit: "Modifier",
        ok: "Ok",
        delete: "Supprimer",
        save: "Enregistrer",
        loading: "Chargement",
        saving: "Enregistrement en cours",
        savingFinished: "Enregistrement terminé",
        savingFailed: "Enregistrement échoué",
        select: "Sélectionner",
        noResultFound: "Aucun résultat trouvé",
        yes: "Oui",
        no: "Non",
        products: "Produits",
        noOption: "Aucune option",
        notFilled: "Non renseigné",
        today: "Aujourd'hui",
        invalidDate: "Date invalide",
        titleContains: "Titre contient",
        perPage: 'Par page',
        of: 'sur'
    },
}

export type CoreTranslation = typeof CORE_FR;
