import React from "react";
import {Badge, Box, Button, Grow, IconButton} from "@material-ui/core";
import {Edit, Save} from "@material-ui/icons";
import {useObservable} from "rxjs-hooks";
import {useTranslation} from "react-i18next";
import RestoreIcon from "@material-ui/icons/Restore";
import {useServicesContext} from "../../../../../services/context";

export default function DeliverablesButtons() {
    const {deliverablesFormService} = useServicesContext();
    const selectionCount = useObservable<number>(() => deliverablesFormService.selectionCount$, 0);
    const changesCount = useObservable(() => deliverablesFormService.changesCount$, 0);
    const {t} = useTranslation();
    return <Box>
        <Grow in={!!changesCount} mountOnEnter unmountOnExit>
            <IconButton onClick={() => deliverablesFormService.reset()}
                        type={"button"}
                        color={"default"}>
                <RestoreIcon/>
            </IconButton>
        </Grow>
        <Grow in={!!changesCount} mountOnEnter unmountOnExit>
            <Badge badgeContent={changesCount} color="secondary" invisible={changesCount == 0}>
                <Button
                    // size="small"
                    onClick={() => deliverablesFormService.save()}
                    disabled={changesCount == 0}
                    variant="contained"
                    color="primary"
                    startIcon={<Save/>}
                    aria-label="filter list">
                    {t('c.common.save')}
                </Button>
            </Badge>
        </Grow>
        <Grow in={!!selectionCount} mountOnEnter unmountOnExit>
            <Badge
                color="secondary"
                badgeContent={selectionCount}
                invisible={!selectionCount}>
                <Box ml={2}>
                    <Button
                        // size="small"
                        disabled={!selectionCount}
                        variant="contained"
                        color="primary"
                        onClick={() => deliverablesFormService.open()}
                        startIcon={<Edit/>}
                    >{t('c.common.edit')}</Button>
                </Box>
            </Badge>
        </Grow>
    </Box>
}
