import {FilterBaseService} from "../../core/services/base/filter.service";
import {DeliverableFilter, DeliverableSubFilter} from "../types/deliverable-filter.types";
import {Deliverable} from "../types/deliverable.types";
import {EnumItem} from "../../core/types/common";

export class DeliverablesFilterService extends FilterBaseService<Deliverable, DeliverableFilter, DeliverableSubFilter> {
    constructor() {
        super();
        this.searchQuery$.subscribe((query) => this.setSubFilter({
            productTitle: query
        }));
    }

    public isDeliveryLinkedToSeries(enumItem: EnumItem | null): boolean {
        return enumItem?.code === 'series' ?? false;
    }

    public includes(deliverable: Deliverable, subFilter: DeliverableSubFilter): boolean {
        return (!subFilter.isLinkedTo || deliverable.isLinkedToSeries === this.isDeliveryLinkedToSeries(subFilter.isLinkedTo))
            && (!subFilter.deliverableType?.length || subFilter.deliverableType.some((item) => item.id == deliverable.deliverableType?.id))
            && this.includesEnumItem(deliverable.status, subFilter.status)
            && (!subFilter.expectedDeliverableReceptionDateClient || subFilter.expectedDeliverableReceptionDateClient.isSame(deliverable.expectedDeliverableReceptionDateClient, 'day'))
            && (!subFilter.expectedDeliverableReceptionDate || subFilter.expectedDeliverableReceptionDate.isSame(deliverable.expectedDeliverableReceptionDate, 'day'))
            && (!subFilter.actualDeliverableReceptionDate || subFilter.actualDeliverableReceptionDate.isSame(deliverable.actualDeliverableReceptionDate, 'day'))
            && (!subFilter.episodeNumberInterval?.length || (!!deliverable.episodeNumber && this.getNumbersInterval(subFilter.episodeNumberInterval).includes(+deliverable.episodeNumber)))
            && (!subFilter.productTitle || (!!deliverable.productTitle && deliverable.productTitle?.toLowerCase().includes(subFilter.productTitle.toLowerCase())))
            && (!subFilter.noReceptionDate || !deliverable.expectedDeliverableReceptionDate)
    }
}
