import React from "react";
import {useServicesContext} from "../../../services/context";
import {useObservable} from "rxjs-hooks";
import CustomTextField from "../../../../core/components/CustomTextField";
import {Box, Divider, Paper} from "@material-ui/core";
import CustomToolbar from "../../../../core/components/CustomToolbar";

export default function ProductDetailsFields() {
    const {productsService} = useServicesContext();
    const selectedProduct = useObservable(() => productsService.selected$);
    return  <Paper>
        <CustomToolbar title="Informations Générales"/>
        <Divider/>
        <Box p={3} display="grid" gridTemplateColumns="repeat(3,1fr)"
             gridRowGap=".5rem"
             gridColumnGap="1rem">
            <CustomTextField
                label="Titre"
                value={selectedProduct?.title}
                InputProps={{
                    readOnly: true,
                }}
            />
            <CustomTextField
                label="Contrat/Entente"
                value={selectedProduct?.type?.title}
                InputProps={{
                    readOnly: true,
                }}
            />
            <CustomTextField
                label="N°Contrat"
                value={selectedProduct?.contractNumber}
                InputProps={{
                    readOnly: true,
                }}
            />
            <CustomTextField
                label="Producteur/Distributeur"
                value={selectedProduct?.producerDistributor?.title}
                InputProps={{
                    readOnly: true,
                }}
            />
            <CustomTextField
                label="Type de Produit"
                value={selectedProduct?.productType?.title}
                InputProps={{
                    readOnly: true,
                }}
            />
            <CustomTextField
                label="Nb. d'épisodes"
                value={selectedProduct?.episodes ? selectedProduct?.episodes : '-'}
                InputProps={{
                    readOnly: true,
                }}
            />
        </Box>
    </Paper>
}
