import {DataTableContext} from "./context";
import React, {useEffect, useState} from "react";
import {DataTableProps} from "./types";
import {DataTableService} from "./service";
import DataTableContent from "./DataTableContent";

export default function DataTable<T>({data$, pagination, columns, multiSelect, toolbar, onSelectChange, onRowClick, onDataChange, onServiceCreated}: DataTableProps<T>) {
    const [service] = useState<DataTableService<T>>(new DataTableService());

    useEffect(() => {
        service.setColumns(columns);
    }, [columns]);

    useEffect(() => {
        service.setPagination(pagination || {});
    }, [pagination]);

    useEffect(() => {
        service.setMultiSelect(multiSelect ?? false);
    }, [multiSelect]);

    useEffect(() => {
        service.setToolbarProps(toolbar ?? {});
    }, [toolbar]);

    useEffect(() => {
        service.initHandlers({
            onSelectChange,
            onRowClick,
            onDataChange,
            onServiceCreated
        });
    }, [onSelectChange, onRowClick]);

    useEffect(() => () => {
        if (service) {
            service.dispose();
        }
    }, []);

    /* IMPORTANT: Leave subscription as the last step after setting all service options */
    useEffect(() => {
        service.subscribeTo(data$);
    }, [data$]);

    if (!service)
        return null;

    return <DataTableContext.Provider value={service}>
        <DataTableContent/>
    </DataTableContext.Provider>
}
