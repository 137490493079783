import React, {useEffect} from "react";
import {useServicesContext} from "../../../services/context";
import DataTable from "../../../../core/components/DataTable/DataTable";
import {DataTableColumn} from "../../../../core/components/DataTable/types";
import {useTranslation} from "react-i18next";
import {Product} from "../../../types/product.types";

export default function ProductDataTableContent() {
    const {productsService, productsFilterService} = useServicesContext();
    const {t} = useTranslation();

    const columns: DataTableColumn<Product>[] = [
        {id: 'type', label: 'Contrat/Entente'},
        {id: 'title', label: 'Titre'},
        {id: 'episodes', label: "Nb. d'épisodes", render: row => row.episodes ? row.episodes : '-'},
        {id: 'productType', label: 'Type de Produit'},
        {id: 'producerDistributor', label: 'Producteur/Distributeur'},
    ];

    useEffect(() => {
        productsService.fetch();
        productsFilterService.toggle(true);
        // productsService.select(1);
    }, []);

    return <DataTable
        columns={columns}
        data$={productsService.dataState$}
        getId={(row) => row.id}
        onRowClick={(row) => productsService.select(row.id)}
        toolbar={{
            title: t("c.common.products"),
        }}
    />;
}
