import {RepeatItem} from "../../utils/array";
import {generateDummyRow} from "./utils";
import {TableCell, TableRow} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, {useEffect} from "react";
import {DataTableColumnRowInfo, MultiSelectType} from "./types";

export default function DataTableLoadingRows<T>({rowsPerPage, columns, multiSelect}: { rowsPerPage: number, columns: DataTableColumnRowInfo<T>[], multiSelect?: MultiSelectType<T> }) {
    return <React.Fragment>
        {
            RepeatItem(rowsPerPage, generateDummyRow(columns)).map((r, index) =>
                <TableRow key={index}>
                    {
                        multiSelect && <TableCell
                            style={{width: '15px'}}
                        >
                            <Skeleton variant="text"
                                      animation="wave"/>
                        </TableCell>
                    }
                    {
                        columns.map((c, index) =>
                            <TableCell key={index}>
                                <Skeleton variant="text"
                                          animation="wave"/>
                            </TableCell>
                        )
                    }
                </TableRow>
            )
        }
    </React.Fragment>
}
