import React, {useEffect} from "react";
import {useServicesContext} from "../../../../../services/context";
import {map, startWith, tap} from "rxjs/operators";
import {combineLatest} from "rxjs";
import DataTable from "../../../../../../core/components/DataTable/DataTable";
import {DataState, DataTableColumn} from "../../../../../../core/components/DataTable/types";
import {useTranslation} from "react-i18next";
import {Product} from "../../../../../types/product.types";
import {Deliverable} from "../../../../../types/deliverable.types";
import DeliverablesDataTableHeader from "./DeliverablesDataTableHeader";
import {useObservable} from "rxjs-hooks";
import DeliverablesValidationBadge from "./DeliverablesValidationBadge";
import DeliverablesSeriesIcon from "./DeliverablesSeriesIcon";
import DeliverablesExpectedDateControl from "./DeliverablesExpectedDateControl";


export default function DeliverablesDataTableContent() {
    const {productsService, deliverablesService, deliverablesFormService} = useServicesContext();
    const selectedProduct = useObservable(() => productsService.selected$);
    const selectedProductIsSeries = useObservable(() => productsService.selectedProductIsSeries$);

    if (!selectedProduct)
        return null;


    const seriesColumns: DataTableColumn<Deliverable>[] = selectedProductIsSeries ? [
        {
            id: 'isLinkedToSeries', label: 'Série',
            render: (row) => row.isLinkedToSeries ? <DeliverablesSeriesIcon/> : '-'
        },
        {id: 'episodeNumber', label: 'N°épisode', render: row => row.episodeNumber ? row.episodeNumber : '-'},
        {
            id: 'productTitle', label: 'Titre Produit'
        },
    ] : [];
    
    const columns: DataTableColumn<Deliverable>[] = [
        ...seriesColumns,
        // @ts-ignore
        {id: 'deliverableType', label: 'Type du Livrable'},
        {
            id: 'expectedDeliverableReceptionDate',
            render: (deliverable, onChange) => <DeliverablesExpectedDateControl
                deliverable={deliverable}
                onChange={onChange}/>,
            comparator: (a, b) => !!a.expectedDeliverableReceptionDate?.isSame(b.expectedDeliverableReceptionDate, 'day'),
            orderAs: "moment",
            label: 'Proposition Date de Livraison Prévue',
        },
        {
            id: 'expectedDeliverableReceptionDateClient',
            render: row => (
                !!row.expectedDeliverableReceptionDateClient &&
                row.expectedDeliverableReceptionDate?.toISOString() !== row.expectedDeliverableReceptionDateClient?.toISOString()
                && !row.actualDeliverableReceptionDate
                    ? <span style={{color: '#c5003b'}}>
                        {row.expectedDeliverableReceptionDateClient?.format('YYYY-MM-DD') ?? '-'}
                    </span>
                    : row.expectedDeliverableReceptionDateClient?.format('YYYY-MM-DD') ?? '-'
            ),
            orderAs: "moment",
            label: 'Date de Livraison Prévue'
        },
        {
            id: 'actualDeliverableReceptionDate',
            render: row => row.actualDeliverableReceptionDate?.format('YYYY-MM-DD') ?? '-',
            orderAs: "moment",
            label: 'Date de Livraison Réelle'
        },
        {
            id: 'status', label: 'Statut',
            render: (row) => <DeliverablesValidationBadge badge={row.status}
                                                          actualDeliverableReceptionDate={row.actualDeliverableReceptionDate}
                                                          expectedDeliverableReceptionDate={row.expectedDeliverableReceptionDate}
                                                          rejectionCause={row.rejectionCause}
                                                          status={row.status}
            />
        },
    ];

    return <DataTable
        columns={columns}
        data$={deliverablesService.dataState$}
        multiSelect={(row) => !row?.actualDeliverableReceptionDate ?? true}
        getId={(row) => row.id}
        onServiceCreated={(service) => deliverablesFormService.register(service)}
        onDataChange={(changes) => {
            console.log('changes', changes)
            return deliverablesFormService.setChanges(changes)
        }}
        onSelectChange={(rows) => deliverablesFormService.selectLines(rows)}
        toolbar={{
            // title: "Livrables",
            component: <DeliverablesDataTableHeader/>
        }}
    />;
}
