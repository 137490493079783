import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Box, Divider} from "@material-ui/core";
import React from "react";
import {createStyles, lighten, makeStyles, Theme} from "@material-ui/core/styles";
import {useDataTableContext} from "./context";
import {useObservable} from "rxjs-hooks";
import CustomToolbar from "../CustomToolbar";

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingTop: theme.spacing(.5),
            paddingBottom: theme.spacing(.5),
            // : lighten(theme.palette.secondary.light, .97),
        },
        toolbar: {
            // height: 66
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            // flex: '1 1 100%',
        },
        saveButton: {
            minWidth: 150,
        },
        buttons: {
            marginLeft: 'auto',
        },
        restoreButton: {
            marginRight: theme.spacing(.5)
        }
    }),
);

export function DataTableToolbar<T>() {
    const service = useDataTableContext<T>();
    const {title, buttons, component} = useObservable(() => service.toolbarProps$, {});
    const classes = useToolbarStyles();

    if (!title && !buttons && !component)
        return <React.Fragment/>;

    return <React.Fragment>
        {
            component ?? <CustomToolbar title={title}>
                <Box className={classes.buttons}>
                    {buttons}
                </Box>
            </CustomToolbar>
        }
        <Divider/>
    </React.Fragment>;
}
