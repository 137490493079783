import {combineLatest, iif, merge, Observable, of, Subject} from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    mapTo,
    shareReplay,
    startWith,
    switchMap, take, tap,
    withLatestFrom
} from "rxjs/operators";
import {ApiService} from "../api.service";
import {FilterBaseService} from "./filter.service";
import {EnumItem} from "../../types/common";
import {DataState} from "../../components/DataTable/types";

export abstract class EntityBaseService<TEntity,
    TApiEntityGet,
    TFilter> {
    protected abstract entityName: string;
    protected idKey: string = 'id';
    protected entityEndpoint: string = '/';
    private fetchSubject = new Subject<string>();
    private selectedSubject = new Subject<number | null>();
    private fetch$: Observable<string> = this.fetchSubject.pipe(shareReplay(1));
    public data$: Observable<TEntity[]> = this.initData();
    public loading$ = merge(
        this.filterService.filter$.pipe(mapTo(true)),
        this.fetchSubject.asObservable().pipe(mapTo(true)),
        this.data$.pipe(mapTo(false)),
    ).pipe(
        distinctUntilChanged(),
        shareReplay(1)
    );

    public dataState$: Observable<DataState<TEntity>> = combineLatest([
        this.data$.pipe(startWith([])),
        this.loading$,
    ]).pipe(
        map(([data, loading]) => ({data, loading} as DataState<TEntity>)),
        shareReplay(1)
    );

    public selected$: Observable<TEntity | null> = this.selectedSubject.pipe(
        switchMap((selected) => iif(
            () => !!selected,
            this.data$.pipe(
                map((entities) => entities.find(entity => (entity as any)[this.idKey] == selected)!)
            ),
            of(null)
        )),
        shareReplay(1)
    );

    public isSelected$: Observable<boolean> = this.selected$.pipe(
        map((selected) => !!selected),
        shareReplay(1)
    );

    public constructor(protected apiService: ApiService,
                       protected filterService: FilterBaseService<TEntity, TFilter, any>,
    ) {
    }

    public fetch(endPoint?: string) {
        this.fetchSubject.next(endPoint);
    }

    public refresh() {
        this.fetch$.pipe(
            take(1)
        ).subscribe((endpoint) => this.fetch(endpoint))
    }

    public select(entityId: number) {
        this.selectedSubject.next(entityId);
    }

    public deselect() {
        this.selectedSubject.next(null);
    }

    protected abstract buildQuery(filter: TFilter): string;

    protected abstract mapToEntity(record: TApiEntityGet): TEntity;

    private initData() {
        const fetchResult$ = combineLatest([
            this.filterService.filter$,
            this.fetch$,
        ]).pipe(
            debounceTime(200),
            switchMap(([filter, endPoint]) => this.apiService.get<TApiEntityGet[]>(
                {
                    path: `${endPoint ?? this.entityEndpoint}${this.buildQuery(filter)}`
                })
            ),
            map((result) => result.map(record => this.mapToEntityAndValidate(record))),
            shareReplay(1)
        )
        return combineLatest([
            fetchResult$,
            this.filterService.subFilter$
        ]).pipe(
            map(([result, subFilter]) =>
                result.filter((entity) => this.filterService.includes(entity, subFilter))
            ),
            shareReplay(1)
        );
    }

    protected initEnumItem(id: number, title: string, code?: string): EnumItem | null {
        return id ? {
            id,
            title,
            code
        } : null;
    }

    private mapToEntityAndValidate(record: TApiEntityGet): TEntity {
        const entity: TEntity = this.mapToEntity(record);

        // 1. Validate conversion
        for (const [key, value] of Object.entries(entity)) {
            if (value === undefined) {
                console.warn(`${this.entityName} converted api data is missing ${key}`);
            }
        }
        return entity;
    }
}
