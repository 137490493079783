import {Observable} from "rxjs";
import {filter, map, shareReplay, switchMap, take} from "rxjs/operators";
import {ApiService} from "../api.service";
import {AuthService} from "../auth.service";
import {SortEnum} from "../../utils/array";
import {EnumItem, ToFillEnumItemCode} from "../../types/common";

export interface GetEnumOptions {
    implicit?: boolean,
    parse?: (item: any) => EnumItem,
    addToFillItem?: boolean,
    addToFillItemLabel?: string,
    sorted?: boolean,
}

export abstract class EnumsBaseService<TEnums> {
    public enums$: Observable<TEnums> = this.getEnums().pipe(
        shareReplay(1)
    );

    public constructor(private apiService: ApiService, private authService: AuthService) {
        this.authService.isLoggedIn$.pipe(
            filter(l => l),
            take(1),
            switchMap(() => this.enums$.pipe(take(1)))
        ).subscribe();
    }

    public abstract getEnums(): Observable<TEnums>;

    protected getEnum(path: string, {implicit, parse, addToFillItem, addToFillItemLabel, sorted}: GetEnumOptions = {}): Observable<EnumItem[]> {
        return this.apiService.get<any>({
            path: `${implicit ? '/' : '/enum/'}${path}`
        }).pipe(
            map((result) => result ? result.map((item: any) => parse ? parse(item) : this.parseEnum(item)) : []),
            map(result => {
                const list = sorted ? result : SortEnum(result);
                if (addToFillItem) {
                    const code: ToFillEnumItemCode = '__toFill';
                    return [{
                        id: code,
                        title: addToFillItemLabel ?? "À renseigner",
                        code
                    }, ...list]
                }
                return list;
            }),
        );
    }

    protected parseEnum(item: any): EnumItem {
        return {
            id: item.id,
            title: item.title || item.name,
            code: item.code
        };
    }
}
