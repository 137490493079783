import {ApiProductGet, Product} from "../types/product.types";
import {EntityBaseService} from "../../core/services/base/entity.service";
import {ProductFilter} from "../types/product-filter.types";
import {map, shareReplay} from "rxjs/operators";
import {Observable} from "rxjs";

export class ProductsService extends EntityBaseService<Product, ApiProductGet, ProductFilter> {
    protected entityEndpoint: string = '/product_deliverable';
    protected entityName: string = 'Product';
    public selectedProductIsSeries$: Observable<boolean> = this.selected$.pipe(
        map(selected => !!selected?.isSeries || !!selected?.episodes),
        shareReplay(1)
    )

    protected buildQuery({title, productDeliverablesStatus}: ProductFilter): string {
        const query = [];
        if (title?.length) {
            query.push(`query=${title}`);
        }
        if (productDeliverablesStatus?.id) {
            query.push(`query2=${productDeliverablesStatus?.id}`);
        
        }
        
        return `/${query.length ? '?' : ''}${query.join('&')}`;
    }

    protected mapToEntity(record: ApiProductGet): Product {
        return {
            //id: record.id,
            id: record.product_deliverable_id,
            //type: this.initEnumItem(record.type_id, record.type_name),
            type: record.contract_phase ? {
                id: '',
                title: record.contract_phase
            } : null,
            contractNumber: record.contract_number,
            producerDistributor: this.initEnumItem(record.producer_distributor_id, record.producer_distributor_name),
            productType: record.product_kind ? {
                id: '',
                title: record.product_kind
            } : null,
            title: record.product_title,
            episodes: record.number_of_episodes,
            isSeries: !!record.is_series || !!record.number_of_episodes,
        }
    }
}
